// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-AsyncTableView-ResizerStd-ResizerStd-module__columnResizeHandle--F4mxc {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 2px;
  display: flex;
  cursor: col-resize;
  background-color: transparent;
  z-index: 1;
}
.src-pages-EntityFiltersPage-AsyncTableView-ResizerStd-ResizerStd-module__columnResizeHandle--F4mxc:hover {
  background-color: #afafaf;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/AsyncTableView/ResizerStd/ResizerStd.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;EACA,kBAAA;EACA,6BAAA;EACA,UAAA;AACF;AAAE;EACE,yBAAA;AAEJ","sourcesContent":[".columnResizeHandle {\n  position: absolute;\n  right: 0px;\n  height: 100%;\n  width: 2px;\n  display: flex;\n  cursor: col-resize;\n  background-color: transparent;\n  z-index: 1;\n  &:hover {\n    background-color: rgb(175, 175, 175);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnResizeHandle": `src-pages-EntityFiltersPage-AsyncTableView-ResizerStd-ResizerStd-module__columnResizeHandle--F4mxc`
};
export default ___CSS_LOADER_EXPORT___;
