import { t } from "i18next";
import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { FormWithBlockStore } from "src/components/FormWithBlocks";
import { buildMainBlock } from "src/pages/EntityCardPage/blockBuilder/buildMainBlock";
import { blockFields } from "src/components/FormWithBlocks/blockTypes";
import { ZObjectItem } from "src/types/ZObjectItem";
import {
  ed2entity,
  EdCardValues,
} from "src/pages/EntityCardPage/apiEntityCard";
import { onError } from "src/common/onError";
import { ZEntity } from "src/types/ZEntity";
import { AttrTypeName } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";
import { getViewInfo, ZAttrViewInfo } from "src/common/attrView";
import {
  createBomInstance,
  deleteBoms,
  loadBomSettings,
  loadBomSummary,
  updateBomInstance,
} from "../apiBom";
import { ZBomSettings } from "../BomTypes";
import { BomPositionsStore } from "./BomPositions/BomPositionsStore";
import { BomObjectsMap, loadObjectsMap } from "./BomObjectsMap";
import {
  buildPositionsColumns,
  PosColumn,
} from "./BomPositions/buildPositionsColumns";

export type ParamsBomEditor = {
  row: ZEntity | null;
  title: string;
  submitText: string;
  submit(values: EdCardValues): Promise<EdCardValues>;
};

export const bomControlStore = makeAutoObservable({
  positionStore: new BomPositionsStore(),
  settings: { status: "none" } as RemoteData<ZBomSettings>,
  setSettings(data: RemoteData<ZBomSettings>) {
    this.settings = data;
  },
  curBomId: null as number | null,
  setCurBomId(id: number | null) {
    this.curBomId = id;
  },
  get curBomKey(): string {
    return String(this.curBomId);
  },
  changeTab(keyOrId: number | string) {
    const bomObjId = +keyOrId || null;
    this.setCurBomId(bomObjId);
    if (bomObjId) {
      this.positionStore.load(this.objectServiceId, bomObjId);
    }
  },
  entityData: null as EntityCardDataExt | null,
  setEntityData(data: EntityCardDataExt | null) {
    this.entityData = data;
  },
  get typesMap(): Record<number, string> {
    return this.entityData?.typesMap ?? {};
  },
  formStore: new FormWithBlockStore(),

  objectsMap: null as BomObjectsMap | null,
  setObjectsMap(dict: BomObjectsMap | null) {
    this.objectsMap = dict;
    if (dict) this.positionStore.setObjMap(dict);
  },
  get bomObject(): ZObjectItem | undefined {
    return this.objectsMap?.bomObject;
  },
  get positionObject(): ZObjectItem | undefined {
    return this.objectsMap?.positionObject;
  },

  objectServiceId: 0,
  setObjectServiceId(id: number) {
    this.objectServiceId = id;
  },

  boms: [] as ZEntity[],
  setBoms(list: ZEntity[]) {
    this.boms = list;
  },
  get bomTabs(): { key: string; label: string }[] {
    return this.boms.map((bom) => ({
      key: String(bom.id),
      label: this.getBomName(bom),
    }));
  },
  loadingBoms: false,
  setLoadingBoms(flag: boolean) {
    this.loadingBoms = flag;
  },

  async init(objectServiceId: number, entityData: EntityCardDataExt) {
    try {
      this.setSelectedIds([]);
      this.setBoms([]);
      this.setObjectServiceId(objectServiceId);
      this.setSettings({ status: "wait" });
      this.setEntityData(null);
      this.setCurBomId(null);
      this.setObjectsMap(null);
      const result = await loadBomSettings(objectServiceId);
      if (!result) throw Error("Не выполнена настройка сервиса");

      this.setEntityData(entityData);
      this.setObjectsMap(await loadObjectsMap(result, entityData.typesMap));
      this.setSettings({ status: "ready", result });
      await this.loadBoms();
    } catch (error) {
      this.setSettings({ status: "error", error });
    }
  },
  get entityModelIdEx(): number {
    const entity = this.entityData?.entity;
    if (!entity) throw Error("Expected model entity");
    return entity.id;
  },

  async loadBoms() {
    try {
      this.setLoadingBoms(true);
      this.setBoms(
        await loadBomSummary(this.objectServiceId, this.entityModelIdEx, "all"),
      );
    } catch (e) {
      onError(e);
    } finally {
      this.setLoadingBoms(false);
    }
  },

  get bomBlock() {
    const { bomObject, typesMap } = this;
    if (!bomObject) return blockFields("root", {}, []);
    return buildMainBlock(bomObject, {
      typesMap,
      canUpdate: true,
    });
  },
  get positionBlock() {
    const { positionObject, typesMap } = this;
    if (!positionObject) return blockFields("root", {}, []);
    return buildMainBlock(positionObject, {
      typesMap,
      canUpdate: true,
    });
  },

  async createBom(values: EdCardValues) {
    if (!this.bomObject) throw Error("Empty BOM object");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...data } = ed2entity(values, 0, this.bomObject.id);
    await createBomInstance(this.objectServiceId, this.entityModelIdEx, data);
    await this.loadBoms();
    return values;
  },
  async updateBom(srcRow: ZEntity, values: EdCardValues) {
    const data = ed2entity(values, srcRow.id, srcRow.objectId);
    await updateBomInstance(this.objectServiceId, data);
    await this.loadBoms();
    return values;
  },

  getBomName(bom: ZEntity): string {
    // У объекта BOM будет только один атрибут строковый, остальные — ссылки (c)
    const { bomNameAttrId } = this;
    const attrValue = bom.attributeValues.find(
      ({ attributeId }) => attributeId === bomNameAttrId,
    );
    const name = attrValue?.values?.[0];
    return name || `#${bom.id}`;
  },
  get bomNameAttrId(): number | undefined {
    const { bomObject, typesMap } = this;
    const attr = bomObject?.attributes.find(
      ({ valueType }) => typesMap[valueType] === AttrTypeName.string,
    );
    return attr?.id;
  },
  get bomColumns(): AttrColumnDef[] {
    const { bomObject } = this;
    if (!bomObject) return [];
    return bomObject.attributes.map((attr) => ({
      attr,
      viewInfo: getViewInfo(attr.viewType),
    }));
  },
  selectedIds: [] as number[],
  setSelectedIds(ids: number[]) {
    this.selectedIds = ids;
  },
  async doDeleteBom(): Promise<boolean> {
    try {
      this.setLoadingBoms(true);
      const { selectedIds } = this;
      if (!selectedIds.length) return false;
      this.setSelectedIds([]);
      await deleteBoms(this.objectServiceId, selectedIds);
      await this.loadBoms();
      return true;
    } catch (e) {
      onError(e);
      return false;
    } finally {
      this.setLoadingBoms(false);
    }
  },

  get positionColumns(): PosColumn[] {
    const { settings, objectsMap } = this;
    if (settings.status !== "ready" || !objectsMap) return [];
    return buildPositionsColumns(
      settings.result,
      objectsMap,
      this.positionStore,
      this.typesMap,
    );
  },

  editor: null as ParamsBomEditor | null,
  setEditor(params: ParamsBomEditor | null) {
    this.editor = params;
  },
  startCreate() {
    const typesMap = this.entityData?.typesMap;
    if (!typesMap) return;
    const modelId = this.entityData?.entity?.id; // 718
    if (!modelId) return;
    const modelObjectId = this.entityData?.object?.id;
    const bomObject = this.objectsMap?.bomObject;
    if (!bomObject) return;
    const modelAttr = bomObject.attributes.find(
      ({ referenceId, valueType }) =>
        typesMap[valueType] === AttrTypeName.object &&
        referenceId === modelObjectId,
    );
    if (!modelAttr) return;
    this.setEditor({
      row: {
        id: undefined as unknown as number,
        objectId: bomObject.id,
        attributeValues: [
          {
            attributeId: modelAttr.id,
            values: [String(modelId)],
          },
        ],
      },
      title: this.newBomTitle,
      submitText: t("Add"),
      submit: (values) => this.createBom(values),
    });
  },
  get newBomTitle() {
    return t("New instance of object", { name: this.bomObject?.name });
  },

  startEdit(row: ZEntity) {
    this.setEditor({
      row,
      title: this.getBomName(row),
      submitText: t("Apply"),
      submit: (values) => this.updateBom(row, values),
    });
  },
  closeEditor() {
    this.setEditor(null);
  },
});

export type AttrColumnDef = {
  attr: ZAttribute;
  viewInfo: ZAttrViewInfo | undefined;
};

export type BomControlStore = typeof bomControlStore;
