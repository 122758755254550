import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { LoaderBox } from "src/components/LoaderBox";
import { EntityAnchor } from "src/pages/EntityCardPage/EntityFormWithAnchor/EntityAnchor";
import { Button } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ModalDelete } from "src/components/ModalDelete";
import { BomControlStore } from "../BomControlStore";
import {
  BomPositionsTable,
  BomPositionsTableHeader,
} from "./BomPositionsTable";
import { BomPositionEdit } from "./BomPositionEdit";
import styles from "./BomPositions.module.less";

interface PropsBomPositions {
  store: BomControlStore;
}

const idContent = "PositionsContent";

export const BomPositions: React.FC<PropsBomPositions> = observer(
  ({ store }) => {
    const { positionStore } = store;
    return (
      <LoaderBox
        remoteData={positionStore.data}
        drawReady={() => <Inner store={store} />}
      />
    );
  },
);

const Inner: React.FC<PropsBomPositions> = observer(({ store }) => {
  const { positionStore } = store;
  const { anchors, selectedKeys } = positionStore;
  const selectedCount = selectedKeys.size;
  return (
    <div className={styles.frame}>
      <div className={styles.nav}>
        <EntityAnchor anchors={anchors} containerSelector={`#${idContent}`} />
      </div>
      <div className={styles.main}>
        <div className={styles.tools}>
          <div className={styles.title}>
            {selectedCount > 0 &&
              t("Selected lines count", { count: selectedCount })}
          </div>
          <Button onClick={() => positionStore.expandAll()}>Expand all</Button>
          <Button onClick={() => positionStore.collapseAll()}>
            Collapse all
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            disabled={selectedCount === 0}
            onClick={() => {
              positionStore.startDeletePos();
            }}
          >
            {t("Delete")}
          </Button>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              const { positionObject, bomObject } = store;
              if (positionObject && bomObject) {
                store.positionStore.startCreate(
                  bomObject,
                  positionObject,
                  store.typesMap,
                );
              }
            }}
            disabled={!store.positionObject}
          >
            Add BOM
          </Button>
        </div>
        <div className={styles.header}>
          <BomPositionsTableHeader store={store} />
        </div>
        <div className={styles.content} id={idContent}>
          <BomPositionsTable store={store} />
        </div>
      </div>
      <BomPositionEdit
        store={store.positionStore}
        positionBlock={store.positionBlock}
        formStore={store.formStore}
      />
      <ModalDelete
        open={positionStore.deletingStatus !== "none"}
        onCancel={() => positionStore.setDeletingStatus("none")}
        message={t("Are you sure to delete selected positions?")}
        waiting={positionStore.deletingStatus === "buzy"}
        onOk={() => positionStore.doDeletePos()}
      />
    </div>
  );
});
