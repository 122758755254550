import * as React from "react";
import { t } from "i18next";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "antd";
import { PickerDateProps } from "antd/es/date-picker/generatePicker";
import { ifDef } from "src/common/ifDef";

export type PropsEntityDatePicker = Omit<
  PickerDateProps<Dayjs>,
  "onChange" | "value"
> & {
  value?: string[] | null;
  onChange?(newValue?: string[] | null): void;
};

export const EntityDatePicker: React.FC<PropsEntityDatePicker> = (props) => {
  const { value, onChange, ...restProps } = props;
  const { showTime } = restProps;
  // Формат не соответствует ISO по решению бэка
  const srcFormat = `YYYY-MM-DD${showTime ? " HH:mm:ss" : ""}`;
  const dstFormat = showTime
    ? t("DateTimeFormat") || "DD.MM.YYYY"
    : t("DateFormat") || "DD.MM.YYYY HH:mm";

  return (
    <DatePicker
      allowClear
      style={{ width: "100%" }}
      format={dstFormat}
      placeholder=""
      {...restProps}
      value={ifDef(value?.[0], (str) => dayjs(str, srcFormat))}
      onChange={(dt) =>
        onChange?.(ifDef(dt, (it) => [it.format(srcFormat)]) ?? [])
      }
    />
  );
};

EntityDatePicker.defaultProps = {
  value: undefined,
  onChange: undefined,
};
