import * as React from "react";
import { Button, Spin, Table, TableProps } from "antd";
import { ZEntity } from "src/types/ZEntity";
import { ZObjectItem } from "src/types/ZObjectItem";
import { createItemView } from "src/common/attrView/createItemView/createItemView";
import { EditOutlined } from "@ant-design/icons";
import { AttrColumnDef } from "../BomControlStore";
import styles from "./BomsTable.module.less";

interface PropsBomsTable {
  bomObject: ZObjectItem;
  boms: ZEntity[];
  onRowClick(bom: ZEntity): void;
  loading: boolean;
  columnDefs: AttrColumnDef[];
  typesMap: Record<number, string>;
  onSelect(ids: number[]): void;
  selectedIds: number[];
  onEdit(row: ZEntity): void;
}

export const BomsTable: React.FC<PropsBomsTable> = (props) => {
  const {
    bomObject,
    boms,
    onRowClick,
    loading,
    columnDefs,
    typesMap,
    selectedIds,
    onSelect,
    onEdit,
  } = props;
  const columns = React.useMemo(() => {
    const result: TableProps["columns"] = [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
        width: "80px",
      },
      ...columnDefs.map(({ attr, viewInfo }) => ({
        key: attr.id,
        title: attr.name,
        render: (_text: unknown, row: ZEntity) => {
          const valRec = row.attributeValues.find(
            ({ attributeId }) => attributeId === attr.id,
          );
          const values = valRec?.values;
          if (!values) return null;
          return createItemView(attr, typesMap, values, viewInfo);
        },
      })),
      {
        key: "edit",
        title: " ",
        width: "64px",
        align: "center",
        render: (_text, row: ZEntity) => (
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              onEdit(row);
            }}
          />
        ),
      },
    ];
    return result;
  }, [bomObject.id]);
  const refTbl = React.useRef<HTMLDivElement>(null);
  const [tblHeight, setTblHeight] = React.useState(400);
  React.useEffect(() => {
    const setHeight = () => {
      const { current } = refTbl;
      if (current) {
        let h = current.clientHeight;
        const thead = current.querySelector("thead");
        if (thead) h -= thead.offsetHeight;
        setTblHeight(h);
      }
    };
    setHeight();
    const onResize = () => {
      setHeight();
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div ref={refTbl} className={styles.tableBox}>
      <Spin spinning={loading}>
        <Table
          className={styles.rowsClickable}
          columns={columns}
          dataSource={boms}
          rowKey="id"
          pagination={false}
          rowSelection={{
            type: "checkbox",
            onChange: (ids: React.Key[]) => {
              onSelect(ids as number[]);
            },
            selectedRowKeys: selectedIds,
          }}
          onRow={(row) => ({
            onClick: () => onRowClick(row),
          })}
          scroll={{ y: tblHeight }}
        />
      </Spin>
    </div>
  );
};
