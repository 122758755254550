import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { Button } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ModalDelete } from "src/components/ModalDelete";
import { BomsTable } from "./BomsTable";
import { BomControlStore } from "../BomControlStore";
import { BomSummaryEditor } from "./BomSummaryEditor";
import styles from "./BomSummary.module.less";

interface PropsBomSummary {
  store: BomControlStore;
}

export const BomSummary: React.FC<PropsBomSummary> = observer(({ store }) => {
  const [delOpen, setDelOpen] = React.useState(false);
  const { bomObject, boms, selectedIds } = store;
  if (!bomObject) return null;
  const selectedCount = selectedIds.length;
  return (
    <div className={styles.bomSummary}>
      <div className={styles.header}>
        <div className={styles.selectedCount}>
          {!!selectedCount &&
            t("Selected lines count", { count: selectedCount })}
        </div>
        <Button
          danger
          icon={<DeleteOutlined />}
          disabled={!selectedCount}
          onClick={() => setDelOpen(true)}
        >
          {t("Delete")}
        </Button>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => store.startCreate()}
        >
          Create BOM
        </Button>
      </div>
      <BomsTable
        bomObject={bomObject}
        boms={boms}
        columnDefs={store.bomColumns}
        onRowClick={(bom) => store.changeTab(bom.id)}
        loading={store.loadingBoms}
        typesMap={store.typesMap}
        selectedIds={selectedIds}
        onSelect={(ids) => store.setSelectedIds(ids)}
        onEdit={(row) => {
          store.startEdit(row);
        }}
      />

      <BomSummaryEditor store={store} />
      <ModalDelete
        open={delOpen}
        message={t("Do you want to delete the selected records?")}
        onOk={() =>
          store.doDeleteBom().then((ok) => {
            if (ok) setDelOpen(false);
          })
        }
        onCancel={() => setDelOpen(false)}
        waiting={store.loadingBoms}
      />
    </div>
  );
});
