import { makeAutoObservable } from "mobx";
import { apiLoadPersonList } from "src/common/apiPerson";
import { loadRolesDictCached } from "src/common/apiRoles";
import { onError } from "src/common/onError";
import { ZObjectItem } from "src/types/ZObjectItem";
import { ZPersonRow } from "src/types/ZPersonRow";

export const createPersonCellInfoStore = () =>
  makeAutoObservable({
    wait: false,
    setWait(flag: boolean) {
      this.wait = flag;
    },
    persons: [] as ZPersonRow[],
    setPersons(list: ZPersonRow[]) {
      this.persons = list;
    },
    rolesDict: {} as Record<number, ZObjectItem>,
    setRolesDict(map: Record<number, ZObjectItem>) {
      this.rolesDict = map;
    },
    async init(values: string[]) {
      try {
        this.setWait(true);
        this.setRolesDict(await loadRolesDictCached());
        const result = await apiLoadPersonList({
          roleIds: [],
          userIds: values,
        });
        this.setPersons(result);
      } catch (error) {
        onError(error);
      } finally {
        this.setWait(false);
      }
    },
  });
