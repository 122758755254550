import React from "react";
import { Button, Dropdown, MenuProps } from "antd";
import { SettingsIconStd } from "src/common/icons";
import { EntityCardData } from "src/pages/EntityCardPage/EntityCardStore";
import { DeleteChatButton } from "./DeleteChatButton";
import { ChatListPageStore } from "../../ChatListPageStore";
import { ZChatInfo } from "../../bsChat.types";
import { EditChatButton } from "./EditChatButton";

type PropsChatSettingsMenu = {
  chatInfo: ZChatInfo;
  pageStore: ChatListPageStore;
  cardData: EntityCardData;
};

export const ChatSettingsMenu: React.FC<PropsChatSettingsMenu> = ({
  chatInfo,
  pageStore,
  cardData,
}) => {
  const items: MenuProps["items"] = [
    {
      key: "edit",
      label: (
        <EditChatButton
          cardData={cardData}
          chatInfo={chatInfo}
          pageStore={pageStore}
        />
      ),
    },
    {
      key: "delete",
      label: <DeleteChatButton chatInfo={chatInfo} pageStore={pageStore} />,
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <Button>
        <SettingsIconStd />
      </Button>
    </Dropdown>
  );
};
