import * as React from "react";
import { observer } from "mobx-react-lite";
import { LoaderBox } from "src/components/LoaderBox";
import { Button, Form, Select, Switch } from "antd";
import { t } from "i18next";
import { ZExtraSettings } from "src/types/ZExtraSettings";
import { ExtraSettingsTabStore } from "./ExtraSettingsTabStore";
import { FieldsWithTools } from "../FieldsWithTools";
import { WidthLimitedFields } from "../WidthLimitedFields";

interface PropsExtraSettingsTab {
  store: ExtraSettingsTabStore;
}

export const ExtraSettingsTab: React.FC<PropsExtraSettingsTab> = observer(
  ({ store }) => {
    React.useEffect(() => {
      store.init();
    }, []);
    return (
      <LoaderBox
        remoteData={store.data}
        drawReady={() => <Inner store={store} />}
      />
    );
  },
);

const Inner: React.FC<PropsExtraSettingsTab> = observer(({ store }) => {
  const [form] = Form.useForm<ZExtraSettings>();
  return (
    <Form<ZExtraSettings>
      form={form}
      layout="vertical"
      name="extraSettingsForm"
      initialValues={store.initialValues}
      onFinish={(values) => store.save(values)}
    >
      <FieldsWithTools
        tools={
          <Button
            key="save"
            type="primary"
            loading={store.isLoading}
            onClick={() => form.submit()}
          >
            {t("Save")}
          </Button>
        }
      >
        <WidthLimitedFields>
          <Form.Item name="sso" label="Авторизация по SSO">
            <Switch />
          </Form.Item>
          <Form.Item
            name="extRegUrl"
            label="URL формы регистрации для кнопки на странице авторизации"
          >
            <Select
              allowClear
              options={store.extRegOptions}
              placeholder="Выбрать"
            />
          </Form.Item>
        </WidthLimitedFields>
      </FieldsWithTools>
    </Form>
  );
});
