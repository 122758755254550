import * as React from "react";
import { t } from "i18next";
import { CheckOutlined } from "@ant-design/icons";
import { ZPermissions, ZRolesGroup, ZTypeActions } from "./roleTypes";
import styles from "./RolesEditor.module.less";
import { createActionsSet, fromActionsSet, makeActionKey } from "./actionsSet";

/* eslint jsx-a11y/control-has-associated-label: "off" */

interface PropsRolesEditor {
  columns: ZTypeActions[];
  rows: ZRolesGroup[];
  value?: ZPermissions;
  onChange?(value?: ZPermissions): void;
}

const actionName = (rawName: string): string => {
  const chunks = rawName.split("_");
  if (chunks.length <= 1) return rawName;
  return chunks.slice(1).join("_");
};

export const RolesEditor: React.FC<PropsRolesEditor> = (props) => {
  const { columns, rows, value, onChange } = props;
  const flatActions = columns.flatMap(({ actions }) => actions);
  const actionsSet = createActionsSet(value ?? []);
  const onClick = (roleId: number, actionId: number) => {
    const key = makeActionKey(roleId, actionId);
    const newSet = new Set(actionsSet);
    if (newSet.has(key)) {
      newSet.delete(key);
    } else {
      newSet.add(key);
    }
    onChange?.(fromActionsSet(newSet, columns, rows));
  };
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th />
            {columns.map(({ type, actions }) => (
              <th key={type} colSpan={actions.length}>
                {t(`actionType.${type}`)}
              </th>
            ))}
          </tr>
          <tr>
            <th />
            {flatActions.map(({ id, name }) => (
              <th key={id}>{actionName(name)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(({ groupId, groupName, roles }) => (
            <React.Fragment key={groupId}>
              <tr>
                <th className={styles.groupName}>{groupName}</th>
                {flatActions.map(({ id }) => (
                  <td key={id} className={styles.group} />
                ))}
              </tr>
              {roles.map(({ roleId, roleName }) => (
                <tr key={roleId}>
                  <th className={styles.roleName}>{roleName}</th>
                  {flatActions.map(({ id, name }) => {
                    const checked = actionsSet.has(makeActionKey(roleId, id));
                    return (
                      <td key={id}>
                        <button
                          type="button"
                          className={styles.cell}
                          onClick={() => onClick(roleId, id)}
                          data-testid={`${roleName}::${name}`}
                          data-checked={checked}
                        >
                          {checked ? <CheckOutlined /> : null}
                        </button>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

RolesEditor.defaultProps = {
  value: undefined,
  onChange: undefined,
};
