import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { AnchorItem } from "src/common/anchorItem";
import {
  ed2entity,
  EdCardValues,
  entity2ed,
} from "src/pages/EntityCardPage/apiEntityCard";
import { onError } from "src/common/onError";
import { t } from "i18next";
import { ZEntity } from "src/types/ZEntity";
import { ZObjectItem } from "src/types/ZObjectItem";
import { AttrTypeName } from "src/types/AttrType";
import {
  createBomPosition,
  deleteBomPositions,
  loadBomPositions,
  updateBomPosition,
} from "../../apiBom";
import { bomPosRowId, ZBomPositionRow } from "../../BomTypes";
import {
  ColorRow,
  MaterialDict,
  MaterialRow,
  PosTableRow,
} from "./BomPositionRowTypes";
import { buildMatDict } from "./buildMatDict";
import { BomObjectsMap } from "../BomObjectsMap";

export class BomPositionsStore {
  constructor() {
    makeAutoObservable(this);
  }

  data: RemoteData<ZBomPositionRow[]> = { status: "none" };

  setData(newData: RemoteData<ZBomPositionRow[]>) {
    this.data = newData;
  }

  protected objectServiceId: number | string = 0;

  protected bomObjectEntityId: number = 0;

  collapsed = new Set<string>();

  toggle(key: string) {
    const { collapsed } = this;
    if (collapsed.has(key)) {
      collapsed.delete(key);
    } else {
      collapsed.add(key);
    }
  }

  isCollapsed(key: string): boolean {
    return this.collapsed.has(key);
  }

  expandAll() {
    this.collapsed.clear();
  }

  collapseAll() {
    const { collapsed } = this;
    Object.values(this.materialDict).forEach((mat) => {
      collapsed.add(mat.key);
      Object.keys(mat.colors).forEach((colorKey) => collapsed.add(colorKey));
    });
  }

  selectedKeys = new Set<number>();

  changeSelected(key: number, mode: "hide" | "show" | "toggle") {
    if (mode === "show") {
      this.selectedKeys.add(key);
    } else if (mode === "hide") {
      this.selectedKeys.delete(key);
    } else if (mode === "toggle") {
      if (this.selectedKeys.has(key)) {
        this.selectedKeys.delete(key);
      } else {
        this.selectedKeys.add(key);
      }
    }
  }

  toggleSelect(key: number) {
    this.changeSelected(key, "toggle");
  }

  isSelected(key: number) {
    return this.selectedKeys.has(key);
  }

  get isSelectedAll() {
    if (this.selectedKeys.size === 0) return "none";
    if (this.selectedKeys.size === this.rows.length) return "all";
    return "partial";
  }

  toggleAll() {
    if (this.isSelectedAll === "all") {
      this.selectedKeys.clear();
    } else {
      this.rows.forEach((row) => this.selectedKeys.add(bomPosRowId(row)));
    }
  }

  objMap = {} as BomObjectsMap;

  setObjMap(m: BomObjectsMap) {
    this.objMap = m;
  }

  async load(objectServiceId: number | string, bomObjectEntityId: number) {
    this.collapsed.clear();
    this.selectedKeys.clear();
    this.objectServiceId = objectServiceId;
    this.bomObjectEntityId = bomObjectEntityId;
    try {
      this.setData({ status: "wait" });
      const result = await loadBomPositions(objectServiceId, bomObjectEntityId);
      this.setData({ status: "ready", result });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  async reload() {
    await this.load(this.objectServiceId, this.bomObjectEntityId);
  }

  get anchors(): AnchorItem[] {
    return Object.values(this.materialDict).map(({ key, name }) => ({
      key,
      href: `#${key}`,
      title: name,
    }));
  }

  get rows(): ZBomPositionRow[] {
    return this.data.status === "ready" ? this.data.result : [];
  }

  get materialDict(): MaterialDict {
    return buildMatDict(this.rows, this.objMap);
  }

  get tableRows(): PosTableRow[] {
    const list: PosTableRow[] = [];
    Object.values(this.materialDict).forEach((matRow: MaterialRow) => {
      list.push(matRow);
      if (this.isCollapsed(matRow.key)) return;
      Object.values(matRow.colors).forEach((colorRow: ColorRow) => {
        list.push(colorRow);
        if (this.isCollapsed(colorRow.key)) return;
        colorRow.rows.forEach((row) => {
          list.push({
            type: "position",
            key: bomPosRowId(row),
            row,
          });
        });
      });
    });
    return list;
  }

  async addPosition(values: EdCardValues, bomPositionObjectId: number) {
    const { objectServiceId, bomObjectEntityId } = this;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...data } = ed2entity(values, 0, bomPositionObjectId);
    await createBomPosition(objectServiceId, bomObjectEntityId, data);
    this.reload().catch(onError);
    return values;
  }

  async updatePosition(srcPosEntity: ZEntity, values: EdCardValues) {
    const { objectServiceId, bomObjectEntityId } = this;
    const data = ed2entity(values, srcPosEntity.id, srcPosEntity.objectId);
    await updateBomPosition(objectServiceId, bomObjectEntityId, data);
    this.reload().catch(onError);
    return values;
  }

  editor: ParamsPositionEdit | null = null;

  setEditor(params: ParamsPositionEdit | null) {
    this.editor = params;
  }

  closeEditor() {
    this.setEditor(null);
  }

  startCreate(
    bomObject: ZObjectItem,
    positionObject: ZObjectItem,
    typesMap: Record<number, string>,
  ) {
    const positionObjectId = positionObject.id;
    const bomAttr = positionObject.attributes.find(
      ({ referenceId, valueType }) =>
        referenceId === bomObject.id &&
        typesMap[valueType] === AttrTypeName.object,
    );
    if (!bomAttr) return;
    this.setEditor({
      positionValues: {
        [String(bomAttr.id)]: [String(this.bomObjectEntityId)],
      },
      title: t("New position"),
      submitText: t("Add"),
      submit: (values) => this.addPosition(values, positionObjectId),
    });
  }

  startEdit(row: ZBomPositionRow) {
    this.setEditor({
      positionValues: entity2ed(row.bomPositionEntity),
      title: "Редактирование позиции",
      submitText: t("Apply"),
      submit: (values) => this.updatePosition(row.bomPositionEntity, values),
    });
  }

  deletingStatus: DeletingStatus = "none";

  setDeletingStatus(status: DeletingStatus) {
    this.deletingStatus = status;
  }

  startDeletePos() {
    this.setDeletingStatus("open");
  }

  async doDeletePos() {
    const selected = Array.from(this.selectedKeys);
    if (!selected.length) return;
    try {
      this.setDeletingStatus("buzy");
      await deleteBomPositions(this.objectServiceId, selected);
      await this.reload();
      this.setDeletingStatus("none");
    } catch (e) {
      onError(e);
      this.setDeletingStatus("open");
    }
  }
}

type DeletingStatus = "buzy" | "none" | "open";

interface ParamsPositionEdit {
  positionValues?: EdCardValues;
  title: string;
  submitText: string;
  submit: (values: EdCardValues) => Promise<EdCardValues>;
}
