import * as React from "react";
import { ColorRow } from "../../BomPositionRowTypes";
import { PosColumn } from "../../buildPositionsColumns";
import { bomColorRowStyle } from "../bomTableStyles";
import { ExpandButton } from "../ExpandButton";
import styles from "./BomColorRow.module.less";

interface PropsBomColorRow {
  colorRow: ColorRow;
  columns: PosColumn[];
  collapsed: boolean;
  toggle(): void;
}

export const BomColorRow: React.FC<PropsBomColorRow> = (props) => {
  const { colorRow, columns, collapsed, toggle } = props;
  return (
    <div
      className={styles.colorRow}
      style={bomColorRowStyle(columns)}
      data-first
    >
      <ExpandButton collapsed={collapsed} onClick={toggle} />
      <span>{colorRow.name}</span>
    </div>
  );
};
