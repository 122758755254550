// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartSettings-MChartSettings-module__box--OA_Vs {
  width: 856px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.src-businessServices-services-bsMeasurementChart-MChartSettings-MChartSettings-module__threeCols--_pQm4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
}
.src-businessServices-services-bsMeasurementChart-MChartSettings-MChartSettings-module__section--dV1zC {
  border-top: thin solid silver;
  padding-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartSettings/MChartSettings.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACA;EACI,aAAA;EACA,kCAAA;EACA,SAAA;AACJ;AACA;EACI,6BAAA;EACA,gBAAA;AACJ","sourcesContent":[".box {\n    width: 856px;\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n}\n.threeCols {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    gap: 12px;\n}\n.section {\n    border-top: thin solid silver;\n    padding-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsMeasurementChart-MChartSettings-MChartSettings-module__box--OA_Vs`,
	"threeCols": `src-businessServices-services-bsMeasurementChart-MChartSettings-MChartSettings-module__threeCols--_pQm4`,
	"section": `src-businessServices-services-bsMeasurementChart-MChartSettings-MChartSettings-module__section--dV1zC`
};
export default ___CSS_LOADER_EXPORT___;
