import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { Button, Progress } from "antd";
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { AColumn } from "src/components/tables/AsyncTable";
import { ZAttribute } from "src/types/ZAttribute";
import { createItemView } from "src/common/attrView/createItemView/createItemView";
import { getViewInfo } from "src/common/attrView/getViewInfo";
import { ModalDelete } from "src/components/ModalDelete";
import { ScrollableTable } from "src/components/ScrollableTable";
import { PersonDetailsStore, PersonRow } from "./PersonDetailsStore";

interface PropsPersonDetailsList {
  store: PersonDetailsStore;
  typesMap: Record<number, string>;
  onNewPerson(): void;
  onPerson(id: number): void;
}

const makeColumn = (
  attr: ZAttribute,
  typesMap: Record<number, string>,
): AColumn<PersonRow> => ({
  key: String(attr.id),
  title: attr.name,
  render: (v, row) =>
    createItemView(attr, typesMap, row[attr.id]!, getViewInfo(attr.viewType)),
});

export const PersonDetailsList: React.FC<PropsPersonDetailsList> = observer(
  (props) => {
    const { store, onNewPerson, onPerson, typesMap } = props;
    const { tableStore } = store;
    const columns: AColumn<PersonRow>[] = React.useMemo(
      () => [
        { key: "id", title: "ID", dataIndex: "id" },
        { key: "userLogin", title: "Логин", dataIndex: "userLogin" },
        ...store.roleObject.attributes.map((a) => makeColumn(a, typesMap)),
      ],
      [store],
    );
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const onDelete = () => {
      setDeleteOpen(true);
    };
    const p = store.delProgress;
    return (
      <>
        <ScrollableTable
          store={tableStore}
          title={store.role.roleName}
          tools={
            <>
              <Button
                icon={<DeleteOutlined />}
                disabled={store.countToDelete === 0}
                onClick={onDelete}
              >
                Удалить выделенное
              </Button>
              <Button
                type="primary"
                icon={<UserAddOutlined />}
                onClick={onNewPerson}
              >
                Добавить пользователя
              </Button>
            </>
          }
          columns={columns}
          onRowClick={({ id }) => onPerson(id)}
          showTotal={(count) => t("Total persons count", { count })}
        />

        <ModalDelete
          open={deleteOpen}
          message={t("Are you sure to delete {{count}} persons?", {
            count: store.countToDelete,
          })}
          onCancel={() => setDeleteOpen(false)}
          onOk={() => {
            store.doDelete().finally(() => setDeleteOpen(false));
          }}
          waiting={store.deleting}
          extraContent={
            p && p.count > 1 ? (
              <div style={{ textAlign: "center", marginTop: 16 }}>
                <Progress
                  type="line"
                  steps={p.count}
                  percent={p.percent}
                  status={p.status}
                />
              </div>
            ) : null
          }
        />
      </>
    );
  },
);
