import { Form, Input, Select, Spin } from "antd";
import * as React from "react";
import { onError } from "src/common/onError";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { MultiLangFields } from "src/components/MultiLangFields";
import { ZServiceInfo } from "src/types/ZServiceInfo";
import { loadServicesList } from "src/businessServices/apiBusinessServices";
import { EdBusinessService } from "./EdBusinessService";

/* eslint react/no-unstable-nested-components: "off" */

interface PropsAddBusinessServiceModal {
  open: boolean;
  close: () => void;
  onSuccess: (values: EdBusinessService) => Promise<void>;
}

const fieldName = (key: keyof EdBusinessService) => key;

export const AddBusinessServiceModal: React.FC<PropsAddBusinessServiceModal> = (
  props,
) => {
  const { open, close, onSuccess } = props;
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [list, setList] = React.useState<ZServiceInfo[]>([]);
  const [form] = Form.useForm();
  React.useEffect(() => {
    setLoading(true);
    loadServicesList()
      .then(setList)
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);
  const onFinish = async (values: EdBusinessService) => {
    try {
      setSaving(true);
      await onSuccess(values);
      close();
    } catch (e) {
      onError(e);
    } finally {
      setSaving(false);
    }
  };
  return (
    <ModalVertFixed
      open={open}
      onCancel={close}
      title="Добавление бизнес-сервиса"
      okButtonProps={{ htmlType: "submit", loading: saving }}
      okText="Добавить"
      centered
      height={400}
      modalRender={(content) => (
        <Spin spinning={loading}>
          <Form<EdBusinessService>
            name="addBSForm"
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            {content}
          </Form>
        </Spin>
      )}
    >
      <Form.Item
        name={fieldName("serviceId")}
        label="Вид бизнес-сервиса"
        rules={[{ required: true }]}
      >
        <Select options={list} fieldNames={{ value: "id", label: "name" }} />
      </Form.Item>
      <Form.Item
        name={fieldName("name")}
        label="Название бизнес-сервиса"
        rules={[{ required: true }]}
      >
        <Input allowClear />
      </Form.Item>
      <MultiLangFields basePath={[fieldName("nameLoc")]} />
    </ModalVertFixed>
  );
};
