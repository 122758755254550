// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomMaterialRow-BomMaterialRow-module__materialRow--AC7c7 {
  color: #343A41;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom/BomControl/BomPositions/BomPositionsTable/BomMaterialRow/BomMaterialRow.module.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EAEA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AAAJ","sourcesContent":[".materialRow {\n    color: #343A41;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n\n    display: flex;\n    flex-direction: row;\n    gap: 16px;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"materialRow": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomMaterialRow-BomMaterialRow-module__materialRow--AC7c7`
};
export default ___CSS_LOADER_EXPORT___;
