/**
 * Компонент Select, который получает options при помощи асинхронного запроса
 */

import * as React from "react";
import { Select, SelectProps } from "antd";
import { onError } from "src/common/onError";
import { DefaultOptionType } from "antd/es/select";

export type PropsSelectFromReference = Omit<SelectProps, "options"> & {
  loader: () => Promise<DefaultOptionType[]>;
};

export const SelectFromReference: React.FC<PropsSelectFromReference> = (
  props: PropsSelectFromReference,
) => {
  const { loader, ...commonProps } = props;
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<DefaultOptionType[]>([]);
  React.useEffect(() => {
    setLoading(true);
    loader()
      .then((result) => setOptions(result))
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Select {...commonProps} options={options} loading={loading} />;
};
