import * as React from "react";
import { TreeSelect, TreeSelectProps } from "antd";
import { LockOutlined } from "@ant-design/icons";
import type { BaseSelectRef } from "rc-select";
import { onError } from "src/common/onError";
import { getCatNodesFull } from "src/common/references/categories";
import { searchFilterStdTree } from "src/common/formLogic/searchFilters";
import { CatNode } from "./TreeStore";

/* eslint react/jsx-props-no-spreading: "off" */

export type PropsCategoryTreeSelect = TreeSelectProps<string, CatNode> & {
  valueFieldName?: string;
  considerSelectable?: boolean;
  companyId?: number;
};

export const CategoryTreeSelect: React.FC<PropsCategoryTreeSelect> =
  React.forwardRef<BaseSelectRef, PropsCategoryTreeSelect>(
    (
      {
        value,
        valueFieldName = "id",
        considerSelectable,
        companyId,
        ...props
      }: PropsCategoryTreeSelect,
      ref,
    ) => {
      const [treeData, setTreeData] = React.useState<CatNode[]>(
        props.treeData || [],
      );

      const [loading, setLoading] = React.useState(false);

      const injectSelectable = (nodes: CatNode[]) => {
        nodes.forEach((n) => {
          const selectable =
            n.data.selectable === undefined || n.data.selectable === null
              ? true
              : n.data.selectable;
          const newNode = n;
          newNode.disabled = !selectable;
          newNode.icon = newNode.disabled && <LockOutlined />;
          injectSelectable(n.children || []);
        });
        return nodes;
      };

      // TODO: нужно протестировать, необходимо ли в этом эффекте
      // использовать триггер на value
      React.useEffect(() => {
        if (companyId) {
          setLoading(true);
          getCatNodesFull(companyId)
            .then((data) => {
              if (considerSelectable) {
                setTreeData(injectSelectable(data));
              } else setTreeData(data);
            })
            .catch(onError)
            .finally(() => setLoading(false));
        }
      }, [value, companyId]);

      return (
        <TreeSelect<string, CatNode>
          {...props}
          showSearch
          filterTreeNode={searchFilterStdTree(props.fieldNames)}
          value={value}
          treeData={treeData}
          treeIcon
          fieldNames={{ value: valueFieldName }}
          ref={ref}
          loading={loading}
        />
      );
    },
  );

CategoryTreeSelect.defaultProps = {
  valueFieldName: undefined,
  companyId: undefined,
  considerSelectable: false,
};
