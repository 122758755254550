import React, { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { PlusCircleFilled } from "@ant-design/icons";
import { Alert, Button, Drawer, Select, Space, Spin } from "antd";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { ZAttrViewInfo } from "src/common/attrView";
import { ZAttribute } from "src/types/ZAttribute";
import { ZObjectRefSelector } from "src/common/attrEdit/components";
import { ObjectRefSelectorStore } from "./ObjectRefSelectorStore";
import { SelectedTags } from "./SelectedTags";
import { ObjectRefSelectorLabel } from "./ObjectRefSelectorLabel";
import styles from "./ObjectRefSelector.module.less";

type PropsObjectRefSelector = {
  attrInfo: ZAttribute;
  component: ZObjectRefSelector;
  viewInfo: ZAttrViewInfo | undefined;
  value?: string[];
  disabled?: boolean;
  onChange?: (value?: string[]) => void;
  label: string;
  objectName?: string;
  entityId?: number;
};

export const ObjectRefSelector: React.FC<PropsObjectRefSelector> = observer(
  ({
    attrInfo,
    component,
    viewInfo,
    value,
    disabled,
    onChange,
    label,
    objectName,
    entityId,
  }) => {
    const [open, setOpen] = useState(false);
    const store = useMemo(() => new ObjectRefSelectorStore(), []);
    const { filtersPageStore: filterStore } = store;
    // Итератор используется только для создания новых экземпляров. LPLM-1282
    const isIterator = !!attrInfo.iterator && !entityId;
    const safeValues = value || [];
    // Если компонент в принципе может использоваться для множественного создания, то параметр из описания компонента игнорируется
    const multiple = attrInfo.iterator ? isIterator : !!component.multiple;

    const syncWithValues = (vals: string[]) => {
      store.setValues(vals);
      store.syncSelected(vals);
    };

    useEffect(() => {
      syncWithValues(safeValues);
    }, [value]);

    useEffect(() => {
      if (!viewInfo) return;
      store.init(attrInfo, component, viewInfo, multiple, value, onChange);
    }, [attrInfo.referenceId]);

    const handleClose = () => {
      setOpen(false);
      const safeInitVals = store.initialValues || [];
      onChange?.(safeInitVals);
      syncWithValues(safeInitVals);
    };

    if (!filterStore) return null;
    const options = safeValues.map((eId) => ({
      label: (
        <ObjectRefSelectorLabel
          component={store.component}
          viewInfo={store.viewInfo}
          entityId={Number(eId)}
        />
      ),
      value: String(eId),
    }));

    return (
      <Spin spinning={store.loading}>
        <Select
          disabled={disabled}
          className={styles.select}
          open={false}
          value={value}
          loading={store.loading}
          mode={multiple ? "tags" : undefined}
          options={options}
          suffixIcon={
            !disabled && (
              <PlusCircleFilled
                onClick={() => {
                  setOpen(true);
                }}
              />
            )
          }
          onChange={(list) => {
            onChange?.(list);
          }}
        />
        {isIterator && (
          <Alert
            type="warning"
            showIcon
            message={t(
              "When saving, multiple instances of the object will be created",
              { attr: label, obj: objectName ?? "?" },
            )}
          />
        )}
        <Drawer
          width="75vw"
          open={open && !disabled}
          onClose={handleClose}
          className={styles.drawer}
          title={label}
          footer={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  setOpen(false);
                  store.setInitialValues(value || []);
                }}
              >
                {t("Save")}
              </Button>
              <Button onClick={handleClose}>{t("Cancel")}</Button>
            </Space>
          }
        >
          <SelectedTags options={options} store={store} onChange={onChange} />
          <EntityFiltersTable store={filterStore} />
        </Drawer>
      </Spin>
    );
  },
);

ObjectRefSelector.defaultProps = {
  value: undefined,
  onChange: undefined,
  objectName: undefined,
};
