import React, { createRef, RefObject, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { TableStore } from "src/components/tables/TableStore";
import { classNames } from "src/common/classNames";
import {
  TableSortingButtonSdt,
  TableSelectionCheckbox,
} from "src/pages/EntityFiltersPage/AsyncTableView";
import { onTableParamsChange } from "src/components/tables/AsyncTable/onTableParamsChange";
import { ResizerStd } from "src/pages/EntityFiltersPage/AsyncTableView/ResizerStd";
import styles from "./ATVHeaderStd.module.less";
import { getColumnWidth } from "../getColumnWidths";

export type PropsATVHeaderStd<TRow extends {}, TFilters extends {}> = {
  store: TableStore<TRow, TFilters>;
  useSelection?: boolean;
};

export const ATVHeaderStd = observer(
  <TRow extends {}, TFilters extends {}>({
    store,
    useSelection,
  }: PropsATVHeaderStd<TRow, TFilters>) => {
    const columnRefs = useMemo(
      () =>
        store.finalColumns.reduce(
          (acc, { key }) => ({ ...acc, [key]: createRef<HTMLDivElement>() }),
          {} as Record<string, RefObject<HTMLDivElement>>,
        ),
      [store.columns],
    );

    const getColW = (key: string) => columnRefs[key]?.current?.offsetWidth;

    return (
      <div className={styles.tableHeader}>
        {useSelection && store.rows.length > 0 && (
          <div
            className={classNames([
              styles.headerItem,
              styles.headerItemSelection,
            ])}
          >
            {store.selectionSettings.selectionType === "checkbox" ? (
              <TableSelectionCheckbox store={store} main />
            ) : (
              <div />
            )}
          </div>
        )}
        {store.finalColumns.map((column) => (
          <div
            ref={columnRefs[column.key]}
            className={styles.headerItem}
            key={column.key}
            style={getColumnWidth(store.columnWidths, column.key)}
          >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className={styles.columnHeaderContent}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <TableSortingButtonSdt
                header={column.title}
                column={column}
                onChange={(sorter) =>
                  onTableParamsChange(
                    store,
                    { current: store.page, pageSize: store.pageSize },
                    sorter,
                  )
                }
              />
            </div>
            <ResizerStd
              column={column}
              store={store}
              startWidth={getColW(column.key)}
            />
          </div>
        ))}
      </div>
    );
  },
);
