import { CSSProperties } from "react";
import { PosColumn } from "../buildPositionsColumns";

export const bomTableStyles = (columns: PosColumn[]): CSSProperties => {
  const widths = columns.map(({ width }) => width);
  return {
    gridTemplateColumns: widths.join(" "),
  };
};
export const bomMatRowStyle = (columns: PosColumn[]): CSSProperties => ({
  gridColumn: `span ${columns.length}`,
});

export const bomColorRowStyle = (columns: PosColumn[]): CSSProperties =>
  bomMatRowStyle(columns);
