import { zEntity } from "src/types/ZEntity";
import { z } from "zod";

const zBomMaterialSettings = z.object({
  attributeLinkToMaterialId: z.number(),
  attributeMaterialTypeId: z.number(),
  attributeImageId: z.number(),
  attributeNameId: z.number(),
});

const zBomSupplierSettings = z.object({
  attributeLinkToSupplierId: z.number(),
  attributeNameId: z.number(),
  attributeAddressId: z.number().nullable().optional(),
});

const zBomColorSettings = z.object({
  attributeLinkToColorId: z.number(),
  attributeNameId: z.number(),
  attributeImageId: z.number(),
});

const zBomOptionalAttr = z.object({
  attributeId: z.number(),
  attributeName: z.string().nullable().optional(),
});

export const zBomSettings = z.object({
  bomObjectId: z.number(),
  positionObjectId: z.number(),
  material: zBomMaterialSettings,
  supplier: zBomSupplierSettings,
  color: zBomColorSettings,
  optionalAttributes: zBomOptionalAttr.array(),
});
export type ZBomSettings = z.infer<typeof zBomSettings>;

export const zBomPositionRow = z.object({
  bomPositionEntity: zEntity,
  materialEntity: zEntity.nullable(),
  colorEntity: zEntity.nullable(),
  supplierEntity: zEntity.nullable(),
});
export type ZBomPositionRow = z.infer<typeof zBomPositionRow>;

export const bomPosRowId = (row: ZBomPositionRow): number =>
  row.bomPositionEntity.id;
