import { Alert, Modal } from "antd";
import { t } from "i18next";
import * as React from "react";

interface PropsModalDelete {
  open: boolean;
  message: React.ReactNode;
  waiting?: boolean;
  onOk(): void;
  onCancel(): void;
  extraContent?: React.ReactNode;
}

/**
 * Стандартный компонент модального окна удаления с подтверждением
 */
export const ModalDelete: React.FC<PropsModalDelete> = (props) => {
  const { open, message, waiting, onOk, onCancel, extraContent } = props;
  return (
    <Modal
      open={open}
      okText={t("Delete")}
      okButtonProps={{
        danger: true,
        loading: waiting,
        id: "doDelete",
      }}
      cancelButtonProps={{ disabled: waiting, id: "cancelDelete" }}
      onOk={onOk}
      onCancel={onCancel}
      closeIcon={null}
    >
      <Alert type="warning" message={message} showIcon />
      {extraContent}
    </Modal>
  );
};

ModalDelete.defaultProps = {
  waiting: false,
  extraContent: undefined,
};
