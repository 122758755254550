import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { ZObjectRefSelector } from "src/common/attrEdit/components";
import { ZAttrViewInfo } from "src/common/attrView";
import { composeEntityLabel } from "src/common/entity/composeEntityLabel/composeEntityLabel";
import { AttrTypeName } from "src/types/AttrType";
import { FileImageFilled } from "@ant-design/icons";
import { getEntityCachedRequest } from "src/common/entity/getEntityCachedRequest";
import { onError } from "src/common/onError";
import { Spin } from "antd";
import { appStore } from "src/appStore";
import styles from "./ObjectRefSelector.module.less";

interface PropsObjectRefSelectorLabel {
  component: ZObjectRefSelector | null;
  viewInfo: ZAttrViewInfo | null;
  entityId: number;
}

export const ObjectRefSelectorLabel: React.FC<PropsObjectRefSelectorLabel> = ({
  component,
  viewInfo,
  entityId,
}) => {
  const [label, setLabel] = useState<React.JSX.Element | React.ReactNode[]>();
  const [loading, setLoading] = useState(false);

  const init = async () => {
    try {
      setLoading(true);
      const entity = await getEntityCachedRequest(entityId);
      const l = await composeEntityLabel(
        entity,
        component?.labelAtts || [],
        viewInfo!,
        undefined,
        {
          separator: "comma",
          replace: { [AttrTypeName.image]: <FileImageFilled /> },
        },
      );
      setLabel(l);
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    init();
  }, [entityId]);

  if (loading) return <Spin>...</Spin>;

  return appStore.isNotAuth ? (
    <span>{label}</span>
  ) : (
    <Link
      to={makeUrl(PageUrl.entityCard, { id: entityId })}
      className={styles.entityLink}
      target="_blank"
    >
      {label}
    </Link>
  );
};
