import { z } from "zod";
import { rest } from "src/common/rest";
import { apiAdminUrl } from "src/common/apiUrl";
import { ZObjState, zObjState } from "src/types/ZObjState";
import { optionalLangParams } from "src/lang/langHdr";
import {
  ZRolesGroup,
  ZPermissions,
  ZTypeActions,
  zRolesGroup,
  zTypeActions,
  zPermissionGroupItem,
} from "./roleTypes";
import { EdObjStates } from "../EdObject";

export const loadRoleGroups = async (): Promise<ZRolesGroup[]> => {
  const resp = await rest.get(apiAdminUrl("/groups"));
  return zRolesGroup.array().parse(resp.data);
};

export const loadTypeActions = async (): Promise<ZTypeActions[]> => {
  const resp = await rest.get(
    apiAdminUrl("/actions?types=OBJECT&types=ENTITY&types=ATTRIBUTE"),
  );
  return zTypeActions.array().parse(resp.data);
};

export const loadObjectPermissions = async (
  objectId: number,
  stateId: number | string,
): Promise<ZPermissions> => {
  const resp = await rest.get(apiAdminUrl("/permissions"), {
    params: { objectId, stateId },
  });
  return zPermissionGroupItem.array().parse(resp.data);
};

export const saveObjectPermissions = async (
  stateId: number | string,
  objectId: number,
  roles: ZPermissions,
) => {
  await rest.put(apiAdminUrl("/permissions"), roles, {
    params: { stateId, objectId },
  });
};

export const saveObjectRolesMap = async (
  objectId: number,
  rolesMap: EdObjStates,
) =>
  Promise.all(
    Object.entries(rolesMap).map(([stateId, permissions]) =>
      saveObjectPermissions(stateId, objectId, permissions),
    ),
  );

export const loadAttributePermissions = async (
  attributeId: number,
  stateId: number,
): Promise<ZPermissions> => {
  const resp = await rest.get(apiAdminUrl("/permissions"), {
    params: {
      attributeId,
      stateId,
    },
  });
  return zPermissionGroupItem.array().parse(resp.data);
};

export const saveAttributePermissions = async (
  attributeId: number,
  stateId: number | string,
  roles: ZPermissions,
) => {
  await rest.put(apiAdminUrl("/permissions"), roles, {
    params: {
      attributeId,
      stateId,
    },
  });
};

export const loadObjectStates = async (
  objectId: number,
  options: {
    translate?: boolean;
  } = {},
): Promise<ZObjState[]> => {
  const resp = await rest.get(apiAdminUrl("/states"), {
    params: { objectId },
    ...optionalLangParams(options.translate),
  });
  return zObjState.array().min(1).parse(resp.data);
};

export const makeObjectStatesMap = (list: ZObjState[]) =>
  list.reduce(
    (acc, curr) => {
      acc[curr.id] = curr.name;
      return acc;
    },
    {} as Record<number, string>,
  );

export const createObjectState = async (
  objectId: number,
  name: string,
): Promise<ZObjState> => {
  const resp = await rest.post(apiAdminUrl("/states"), [name], {
    params: { objectId },
  });
  const result = z.tuple([zObjState]).parse(resp.data);
  return result[0];
};

export const deleteObjectState = async (stateId: number): Promise<void> => {
  await rest.delete(apiAdminUrl(`/states/${stateId}`));
};

/**
 * Эта функция вызывается после сабмита формы объекта.
 * Там пользователь мог удалить какие-то существующие состояния.
 * Но они удалились только из списка states.
 * А после сабмита это нужно выполнить и на серверной стороне.
 * Для этого получаем полный список состояний, сравниваем с тем который пришел из формы и удаляем разницу
 * @param objectId
 * @param states Это список состояний, которые остались после редактирования (они не удаляются)
 */
export const smartDeleteObjectStates = async (
  objectId: number,
  states: ZObjState[],
): Promise<void> => {
  const oldStates: ZObjState[] = await loadObjectStates(objectId);
  // Здесь собираем id тех состояний, которые нужно удалять
  const oldStateIds = new Set(oldStates.map(({ id }) => id));
  states.forEach(({ id }) => oldStateIds.delete(id));
  if (oldStateIds.size > 0) {
    await Promise.all(
      Array.from(oldStateIds).map((stateId) => deleteObjectState(stateId)),
    );
  }
};
