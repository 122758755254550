import { apiBomUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { AxiosError } from "axios";
import { zEntity, ZEntity } from "src/types/ZEntity";
import {
  zBomPositionRow,
  ZBomPositionRow,
  ZBomSettings,
  zBomSettings,
} from "./BomTypes";

export const loadBomSettings = async (
  objectServiceId: number | string,
): Promise<ZBomSettings | null> => {
  try {
    const resp = await rest.get(apiBomUrl(`/bom/object/${objectServiceId}`));
    return zBomSettings.parse(resp.data);
  } catch (e) {
    // К сожалению, пока нет возможности узнать, существуют ли настройки или нет
    const axiosError = e as AxiosError;
    if (axiosError?.isAxiosError) {
      if (axiosError?.response?.status === 404) {
        return null;
      }
    }
    throw e;
  }
};

type BomSettingsToSave = ZBomSettings & {
  objectServiceId: number;
};

export const saveBomSettings = async (
  objectServiceId: number,
  settings: ZBomSettings,
) => {
  const data: BomSettingsToSave = {
    objectServiceId,
    ...settings,
  };
  await rest.post(apiBomUrl("/bom/object"), data);
};

export const loadBomSummary = async (
  objectServiceId: number | string | "all",
  entityModelId: number,
  entityId: number | string,
) => {
  const resp = await rest.get(
    apiBomUrl(`/bom/summary/${objectServiceId}/${entityModelId}/${entityId}`),
  );
  return zEntity.array().parse(resp.data);
};

export const createBomInstance = async (
  objectServiceId: number | string,
  entityModelId: number | string,
  data: Omit<ZEntity, "id">,
) => {
  await rest.post(
    apiBomUrl(`/bom/summary/${objectServiceId}/${entityModelId}`),
    data,
  );
};

export const updateBomInstance = async (
  objectServiceId: number | string,
  data: ZEntity,
) => {
  await rest.put(apiBomUrl(`/bom/summary/${objectServiceId}/${data.id}`), data);
};

export const deleteBoms = async (
  objectServiceId: number | string,
  ids: number[],
) => {
  await Promise.all(
    ids.map((id) =>
      rest.delete(apiBomUrl(`/bom/summary/${objectServiceId}/${id}`)),
    ),
  );
};

export const loadBomPositions = async (
  objectServiceId: number | string,
  bomObjectEntityId: number,
): Promise<ZBomPositionRow[]> => {
  const resp = await rest.get(
    apiBomUrl(`/bom/entity/${objectServiceId}/${bomObjectEntityId}/all`),
  );
  return zBomPositionRow.array().parse(resp.data);
  // return makePositionStub();
};

export const createBomPosition = async (
  objectServiceId: number | string,
  bomObjectEntityId: number,
  data: Omit<ZEntity, "id">,
) => {
  await rest.post(
    apiBomUrl(`/bom/entity/${objectServiceId}/${bomObjectEntityId}`),
    data,
  );
};

export const updateBomPosition = async (
  objectServiceId: number | string,
  bomObjectEntityId: number,
  data: ZEntity,
) => {
  await rest.put(apiBomUrl(`/bom/entity/${objectServiceId}/${data.id}`), data);
};

export const deleteBomPositions = async (
  objectServiceId: number | string,
  positionIds: number[],
) => {
  await rest.delete(apiBomUrl(`/bom/entity/${objectServiceId}`), {
    data: positionIds,
  });
};
