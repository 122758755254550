import React, { useEffect, useMemo } from "react";
import { ZAttribute } from "src/types/ZAttribute";
import { PersonSelect, PropsPersonSelect } from "src/components/PersonSelect";
import { Tag } from "antd";
import { getEditorInfo } from "src/common/attrEdit";
import { getComponentEditor } from "src/pages/EntityCardPage/blockBuilder/createItem2";
import { makeAutoObservable } from "mobx";
import { getIdLabels } from "src/references/getIdNames";
import { AttrTypeName, makeDictNameById } from "src/types/AttrType";
import { onError } from "src/common/onError";
import { observer } from "mobx-react-lite";

const makeStore = () =>
  makeAutoObservable({
    typesMap: {} as Record<number, string>,
    setTypesMap(list: Record<number, string>) {
      this.typesMap = list;
    },
    async init() {
      getIdLabels("attrType", "attrType")
        .then((data) => this.setTypesMap(makeDictNameById(data)))
        .catch(onError);
    },
  });

type PropsPersonSelectCompex = Omit<PropsPersonSelect, "attr"> & {
  attributes: ZAttribute[];
};

export const PersonSelectCompex: React.FC<PropsPersonSelectCompex> = observer(
  ({ attributes, ...props }) => {
    const store = useMemo(() => makeStore(), []);

    useEffect(() => {
      store.init();
    }, []);

    const userListAtts = attributes.filter(
      ({ valueType }) => store.typesMap[valueType] === AttrTypeName.usersList,
    );

    if (userListAtts.length === 0)
      return <Tag color="red">нет такого атрибута</Tag>;

    /**
     * По договоренности с Максом, тут след. стратегия:
     * собитается комплесный атрибут из всех атрибутов сущности с типом "userList",
     * со всеми настройками и уникальными значениями, чтобы разом отобразить его в одном селекте пользователей
     */
    const complexViewAtts = userListAtts.reduce(
      (acc, curr) => {
        const editorInfo = getEditorInfo(curr.viewStyles);
        const component = getComponentEditor(editorInfo, store.typesMap, curr);
        if (component?.editor !== "PersonSelect") return acc;
        const viewAtts = component?.rolesViewAtts;
        return { ...viewAtts, ...acc };
      },
      {} as Record<string, string[]>,
    );

    const complexAttr = userListAtts.reduce((acc, curr) => {
      const keySet = new Set(acc.values);
      const uinqVals = curr.values?.filter((v) => !keySet.has(v));
      return {
        ...acc,
        values: [...(acc.values || []), ...(uinqVals || [])],
      };
    }, userListAtts[0]!);

    return (
      <PersonSelect
        {...props}
        attr={complexAttr}
        rolesViewAtts={complexViewAtts}
        multiple
      />
    );
  },
);
