import * as React from "react";
import { observer } from "mobx-react-lite";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { TShapeForm } from "src/components/TShapeForm/TShapeForm";
import { Form } from "antd";
import {
  FormBlockDef,
  FormWithBlockStore,
} from "src/components/FormWithBlocks";
import { BomPositionsStore } from "./BomPositionsStore";

interface PropsBomPositionEdit {
  store: BomPositionsStore;
  positionBlock: FormBlockDef;
  formStore: FormWithBlockStore;
}

export const BomPositionEdit: React.FC<PropsBomPositionEdit> = observer(
  (props) => {
    const { store, positionBlock, formStore } = props;
    const { editor } = store;
    const [form] = Form.useForm();
    React.useEffect(() => {
      if (editor) {
        form.resetFields();
        if (editor.positionValues) {
          form.setFieldsValue(editor.positionValues);
        }
      }
    }, [editor]);
    if (!editor) return null;
    return (
      <ModalVertFixed
        open={!!editor}
        onCancel={() => store.closeEditor()}
        footer={null}
        width="80vw"
        centered
      >
        <TShapeForm
          title={editor.title}
          form={form}
          formProps={{
            name: "BomPosition",
            block: positionBlock,
            store: formStore,
            submitText: editor.submitText,
            submit: (values) => editor.submit(values),
            onSuccess: () => store.closeEditor(),
          }}
        />
      </ModalVertFixed>
    );
  },
);
