import * as React from "react";
import { Spin, Tag } from "antd";
import { observer } from "mobx-react-lite";
import { ZRolesViewAtts } from "src/common/roles/ZRolesViewAtts";
import { PersonLabel } from "./PersonLabel/PersonLabel";
import { createPersonCellInfoStore } from "./createPersonCellInfoStore";
import { ZAttrViewInfo } from "../../zAttrViewInfo";
import { seedSeparators } from "../../viewFormItems/Separator";
import { ZModePersonCell, ZPersonCellInfo } from "../../componentsDefs";
import styles from "./PersonCell.module.less";

interface PropsPersonCellInfo {
  rolesViewAtts?: ZRolesViewAtts;
  values: string[];
  viewInfo?: ZAttrViewInfo;
  viewProps?: ZPersonCellInfo;
}

const createLabel = (
  viewProps: ZPersonCellInfo | undefined,
  content: React.JSX.Element | React.ReactNode[],
  viewInfo?: ZAttrViewInfo,
) => {
  const lablelMap: Partial<Record<ZModePersonCell, React.ReactNode>> = {
    tag: (
      <Tag
        color={viewProps?.modeMeta?.tagColor}
        style={{ ...viewInfo?.styles, maxWidth: "100%" }}
        className={styles[viewInfo?.appearance?.view?.overflow || ""]}
      >
        {content}
      </Tag>
    ),
    standart: content,
  };
  const mode = viewProps?.mode;
  return mode ? lablelMap[mode] : lablelMap.standart;
};

export const PersonCellInfo: React.FC<PropsPersonCellInfo> = observer(
  ({ values, rolesViewAtts, viewInfo, viewProps }) => {
    const store = React.useMemo(() => createPersonCellInfoStore(), []);
    const empty = !values || !values.length;
    React.useEffect(() => {
      if (!empty) store.init(values);
    }, [values]);
    if (empty) return null;
    return (
      <Spin spinning={store.wait} size="small" delay={50}>
        <div style={viewInfo?.styles ?? undefined}>
          {seedSeparators(
            store.persons.map((person) => ({
              key: person.id,
              content: createLabel(
                viewProps,
                <PersonLabel
                  person={person}
                  rolesViewAtts={rolesViewAtts}
                  rolesDict={store.rolesDict}
                  viewInfo={viewInfo}
                />,
                viewInfo,
              ),
            })),
            "newLine",
          )}
        </div>
      </Spin>
    );
  },
);
