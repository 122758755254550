// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__centerBox--BkXrF {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__matImage--UmoeP {
  max-width: 60px;
  max-height: 60px;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__matCell--Jcjod {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__colorImage--sMxCK {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: thin solid #E1E3E6;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__highName--CQh99 {
  color: #343A41;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__secondText--a9CDx {
  color: #767C84;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__supplierCell--NuCiv {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom/BomControl/BomPositions/BomPositionsTable/BomPositionTableRow.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACA;EACI,eAAA;EACA,gBAAA;AACJ;AACA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;AACA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,0BAAA;AACJ;AACA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AACA;EACI,cAAA;EACA,6CAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AACA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ","sourcesContent":[".centerBox {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.matImage {\n    max-width: 60px;\n    max-height: 60px;\n}\n.matCell {\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n}\n.colorImage {\n    width: 16px;\n    height: 16px;\n    border-radius: 4px;\n    border: thin solid #E1E3E6;\n}\n.highName {\n    color: #343A41;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;    \n}\n.secondText {\n    color: #767C84;\n    font-feature-settings: 'liga' off, 'clig' off;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;    \n}\n.supplierCell {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centerBox": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__centerBox--BkXrF`,
	"matImage": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__matImage--UmoeP`,
	"matCell": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__matCell--Jcjod`,
	"colorImage": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__colorImage--sMxCK`,
	"highName": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__highName--CQh99`,
	"secondText": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__secondText--a9CDx`,
	"supplierCell": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionTableRow-module__supplierCell--NuCiv`
};
export default ___CSS_LOADER_EXPORT___;
