import * as React from "react";
import { imgSrc } from "src/components/PlmUploader/imgSrc";
import { observer } from "mobx-react-lite";
import { Checkbox } from "antd";
import { bomPosRowId, ZBomPositionRow } from "../../../BomTypes";
import {
  BomObjectsMap,
  getPosColorImgUid,
  getPosMatImgUid,
  getPosMatName,
  getPosSupplierAddr,
  getPosSupplierName,
} from "../../BomObjectsMap";
import styles from "./BomPositionTableRow.module.less";
import { PositionCtrl } from "../buildPositionsColumns";

interface PropsBomPositionCell {
  row: ZBomPositionRow;
  objMap: BomObjectsMap;
}

export const BomPositionMatImage: React.FC<PropsBomPositionCell> = ({
  row,
  objMap,
}) => {
  const src = getPosMatImgUid(row, objMap);
  return (
    src && (
      <div className={styles.centerBox}>
        <img src={imgSrc(src)} alt="" className={styles.matImage} />
      </div>
    )
  );
};

export const BomMaterialCell: React.FC<PropsBomPositionCell> = ({
  row,
  objMap,
}) => {
  const colorImgUid = getPosColorImgUid(row, objMap);
  return (
    <div className={styles.matCell}>
      <div>
        {colorImgUid && (
          <img src={imgSrc(colorImgUid)} className={styles.colorImage} alt="" />
        )}
      </div>
      <div className={styles.highName}>{getPosMatName(row, objMap)}</div>
    </div>
  );
};

export const BomSupplierCell: React.FC<PropsBomPositionCell> = ({
  row,
  objMap,
}) => (
  <div className={styles.supplierCell}>
    <div className={styles.highName}>{getPosSupplierName(row, objMap)}</div>
    <div className={styles.secondText}>{getPosSupplierAddr(row, objMap)}</div>
  </div>
);

type PropsBomRowSelect = PropsBomPositionCell & {
  ctrl: PositionCtrl;
};

export const BomRowSelect: React.FC<PropsBomRowSelect> = observer((props) => {
  const { row, ctrl } = props;
  return (
    <Checkbox
      checked={ctrl.isSelected(bomPosRowId(row))}
      onChange={() => ctrl.toggleSelect(bomPosRowId(row))}
    />
  );
});
