import { ZAttribute } from "src/types/ZAttribute";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { makeDictionary } from "src/common/makeDictionary";
import { ZEntity, ZEntityValue } from "src/types/ZEntity";
import { AttrTypeName } from "src/types/AttrType";
import { ZObjectItem } from "src/types/ZObjectItem";
import { loadObject } from "src/pages/EntityCardPage/apiEntityCard";
import { loadRefOptions } from "src/common/getEntityName";
import { ZOption } from "src/types/ZOption";
import { ifDef } from "src/common/ifDef";
import { t } from "i18next";
import { ZBomPositionRow, ZBomSettings } from "../BomTypes";

export type AttrMap = Record<number, ZAttribute>;
export type BomObjectsMap = {
  bomObject: ZObjectItem;
  positionObject: ZObjectItem;
  posAttrs: AttrMap;
  matAttrs: AttrMap;
  suppAttrs: AttrMap;
  colorAttrs: AttrMap;
  matNameAttr: ZAttribute;
  matTypeAttr: ZAttribute;
  matImageAttr: ZAttribute;
  suppNameAttr: ZAttribute;
  suppAddrAttr: ZAttribute | undefined;
  matTypeOptions: ZOption[];
  colorNameAttr: ZAttribute;
  colorImageAttr: ZAttribute;
};

const loadAttrMap = async (objId: number): Promise<AttrMap> => {
  const attrsList = await loadObjectAttrinbutesAll(objId, { translate: true });
  return makeDictionary(attrsList, ({ id }) => id);
};

const settingsErrMsg = (msg: string) => `Ошибка в настройках сервиса: ${msg}`;

export const loadObjectsMap = async (
  settings: ZBomSettings,
  typesMap: Record<number, string>,
): Promise<BomObjectsMap> => {
  const bomObject = await loadObject(settings.bomObjectId, { translate: true });
  const positionObject = await loadObject(settings.positionObjectId, {
    translate: true,
  });
  const posAttrs = await loadAttrMap(settings.positionObjectId);
  const getObjectId = (attrId: number, name: string): number => {
    const attr = posAttrs[attrId];
    if (!attr)
      throw Error(settingsErrMsg(`Не найдено описание объекта ${name}.`));
    const { referenceId } = attr;
    if (!referenceId)
      throw Error(
        settingsErrMsg(
          `Атрибут ${attr.name} не содержит ссылки на объект ${name}`,
        ),
      );
    return referenceId;
  };
  const materialId = getObjectId(
    settings.material.attributeLinkToMaterialId,
    "material",
  );
  const supplierId = getObjectId(
    settings.supplier.attributeLinkToSupplierId,
    "supplier",
  );
  const colorId = getObjectId(settings.color.attributeLinkToColorId, "color");
  const [matAttrs, suppAttrs, colorAttrs] = await Promise.all([
    loadAttrMap(materialId),
    loadAttrMap(supplierId),
    loadAttrMap(colorId),
  ]);

  const matNameAttr = matAttrs[settings.material.attributeNameId];
  if (!matNameAttr)
    throw Error(settingsErrMsg("Не укакан атрибут названия материала"));
  if (typesMap[matNameAttr.valueType] !== AttrTypeName.string)
    throw Error(settingsErrMsg("Название материала должно быть текстом"));

  const matTypeAttr = matAttrs[settings.material.attributeMaterialTypeId];
  if (!matTypeAttr)
    throw Error(settingsErrMsg("Не указан атрибут типа метериала"));
  if (typesMap[matTypeAttr.valueType] !== AttrTypeName.dictSingle)
    throw Error(settingsErrMsg("Тип материала делжен быть справочником"));
  const matTypeRefid = matTypeAttr.referenceId;
  if (!matTypeRefid)
    throw Error(settingsErrMsg("Не указан справочник типа материалов"));
  const matTypeOptions = await loadRefOptions(matTypeRefid);

  const matImageAttr = matAttrs[settings.material.attributeImageId];
  if (!matImageAttr)
    throw Error(settingsErrMsg("Не указан атрибут изображения материала"));
  if (typesMap[matImageAttr.valueType] !== AttrTypeName.image)
    throw Error(settingsErrMsg("Неподходящий тип изображения материала"));

  const suppNameAttr = suppAttrs[settings.supplier.attributeNameId];
  if (!suppNameAttr)
    throw Error(settingsErrMsg("Не указан атрибут названия поставщика"));
  const suppAddrAttr = ifDef(
    settings.supplier.attributeAddressId,
    (id) => suppAttrs[id],
  );

  const colorNameAttr = colorAttrs[settings.color.attributeNameId];
  if (!colorNameAttr)
    throw Error(settingsErrMsg("Не указан атрибут названия цвета"));
  if (typesMap[colorNameAttr.valueType] !== AttrTypeName.string)
    throw Error(settingsErrMsg("Название цвета должно быть текстом"));

  const colorImageAttr = colorAttrs[settings.color.attributeImageId];
  if (!colorImageAttr)
    throw Error(settingsErrMsg("Не указан атрибут изображения цвета"));
  if (typesMap[colorImageAttr.valueType] !== AttrTypeName.image)
    throw Error(settingsErrMsg("Неподходящий тип изображения цвета"));

  return {
    bomObject,
    positionObject,
    posAttrs,
    matAttrs,
    suppAttrs,
    colorAttrs,
    matNameAttr,
    matTypeAttr,
    matImageAttr,
    suppNameAttr,
    suppAddrAttr,
    matTypeOptions,
    colorNameAttr,
    colorImageAttr,
  };
};

const findAttrValue = (
  entity: ZEntity,
  needAttrId: number,
): ZEntityValue | undefined =>
  entity.attributeValues.find(({ attributeId }) => needAttrId === attributeId)
    ?.values;

export const getPosMatName = (
  row: ZBomPositionRow,
  map: BomObjectsMap,
): string => {
  const val = ifDef(row.materialEntity, (entity) =>
    findAttrValue(entity, map.matNameAttr.id),
  );
  return val?.[0] ?? t("all");
};

export const getPosMatType = (
  row: ZBomPositionRow,
  map: BomObjectsMap,
): ZOption => {
  const refId = ifDef(
    row.materialEntity,
    (entity) => findAttrValue(entity, map.matTypeAttr.id)?.[0],
  );
  if (!refId) return { label: t("all"), value: "" };
  return (
    map.matTypeOptions.find(({ value }) => value === refId) ?? {
      label: refId,
      value: refId,
    }
  );
};

export const getPosMatImgUid = (
  row: ZBomPositionRow,
  map: BomObjectsMap,
): string | undefined =>
  ifDef(
    row.materialEntity,
    (entity) => findAttrValue(entity, map.matImageAttr.id)?.[0],
  );

export const getPosSupplierName = (
  row: ZBomPositionRow,
  map: BomObjectsMap,
): string => {
  const val = row.supplierEntity
    ? findAttrValue(row.supplierEntity, map.suppNameAttr.id)
    : null;
  return val?.[0] ?? "";
};
export const getPosSupplierAddr = (
  row: ZBomPositionRow,
  map: BomObjectsMap,
): string => {
  const val = ifDef(map.suppAddrAttr, (attr) =>
    row.supplierEntity ? findAttrValue(row.supplierEntity, attr.id) : null,
  );
  return val?.[0] ?? "";
};

export const getPosColorName = (
  row: ZBomPositionRow,
  map: BomObjectsMap,
): string | undefined =>
  ifDef(
    row.colorEntity,
    (colorEntity) => findAttrValue(colorEntity, map.colorNameAttr.id)?.[0],
  ) ?? t("all");

export const getPosColorImgUid = (
  row: ZBomPositionRow,
  map: BomObjectsMap,
): string | undefined =>
  ifDef(
    row.colorEntity,
    (colorEntity) => findAttrValue(colorEntity, map.colorImageAttr.id)?.[0],
  );
