import React from "react";
import {
  ApartmentOutlined,
  NodeIndexOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { ZAttribute } from "src/types/ZAttribute";
import { ZIdName } from "src/types/ZIdName";
import { CommonNodeO2, ZLightGroup } from "../../Obj2Nodes";

export interface AttrTreeNode {
  readonly key: React.Key;
  readonly title: React.ReactNode;
  readonly children?: AttrTreeNode[];
  readonly icon: React.ReactNode;
  readonly selectable: boolean;
}

export const makeAttrNodeKey = (
  id: number,
  type: "attr" | "group" | "value",
): React.Key => `${type}-${id}`;

export const parseAttrNodeKey = (
  key: React.Key | undefined,
): [string, number] | undefined => {
  if (typeof key !== "string") return undefined;
  const pair = key.split("-");
  return pair.length === 2 ? [pair[0]!, +pair[1]!] : undefined;
};

export const createAttrTree = (list: CommonNodeO2[]): AttrTreeNode[] =>
  list.reduce((acc: AttrTreeNode[], node) => {
    if (node.type === "group") {
      acc.push(groupNode(node.group, node.children));
    } else if (node.type === "value") {
      acc.push(valueNode(node.value, node.children));
    } else if (node.type === "attr") {
      acc.push(attrNode(node.attr));
    }
    return acc;
  }, []);

const attrNode = (attr: ZAttribute): AttrTreeNode => ({
  key: makeAttrNodeKey(attr.id, "attr"),
  title: <span data-attr-id={attr.id}>{attr.name}</span>,
  icon: <TagOutlined />,
  selectable: true,
});

const groupNode = (
  group: ZLightGroup,
  subNodes: CommonNodeO2[] | undefined,
): AttrTreeNode => ({
  key: makeAttrNodeKey(group.id, "group"),
  title: group.name,
  children: subNodes ? createAttrTree(subNodes) : undefined,
  icon: <ApartmentOutlined />,
  selectable: false,
});

const valueNode = (
  value: ZIdName,
  subNodes: CommonNodeO2[] | undefined,
): AttrTreeNode => ({
  key: makeAttrNodeKey(value.id, "value"),
  title: value.name,
  children: subNodes ? createAttrTree(subNodes) : undefined,
  selectable: false,
  icon: <NodeIndexOutlined />,
});
