import React, { useState } from "react";
import { Button } from "antd";
import { SettingFilled } from "@ant-design/icons";
import { EntityCardData } from "src/pages/EntityCardPage/EntityCardStore";
import { ChatListPageStore } from "../../ChatListPageStore";
import { ZChatInfo } from "../../bsChat.types";
import { ChatSettingsModal } from "./ChatSettingsModal";

type PropsEditChatButton = {
  chatInfo: ZChatInfo;
  pageStore: ChatListPageStore;
  cardData: EntityCardData;
};

export const EditChatButton: React.FC<PropsEditChatButton> = ({
  chatInfo,
  pageStore,
  cardData,
}) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  return (
    <>
      <Button type="text" onClick={openModal}>
        <SettingFilled />
        Редактировать чат
      </Button>
      {open && (
        <ChatSettingsModal
          modalTitle="Редактирование чата"
          okBtnText="Сохранить"
          cardData={cardData}
          submit={(data) =>
            pageStore.updateChat(chatInfo.id, data.name, data.userIds)
          }
          onClose={closeModal}
          initial={chatInfo}
        />
      )}
    </>
  );
};
