import React, { useState } from "react";
import { EntityCardData } from "src/pages/EntityCardPage/EntityCardStore";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ChatListPageStore } from "../ChatListPageStore";
import { ChatSettingsModal } from "../ChatForm/chatSettings/ChatSettingsModal";

type PropsNewChatButton = {
  cardData: EntityCardData;
  pageStore: ChatListPageStore;
};

export const NewChatButton: React.FC<PropsNewChatButton> = observer(
  ({ cardData, pageStore }) => {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const openModal = () => setOpen(true);

    return (
      <>
        <Button onClick={openModal}>
          <PlusCircleOutlined /> Создать чат
        </Button>
        {open && (
          <ChatSettingsModal
            modalTitle="Создать новый чат"
            okBtnText="Создать"
            cardData={cardData}
            submit={(data) => pageStore.createChat(data.name, data.userIds)}
            onClose={closeModal}
          />
        )}
      </>
    );
  },
);
