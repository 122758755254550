import * as React from "react";
import { observer } from "mobx-react-lite";
import { Empty } from "antd";
import { BomControlStore } from "../../BomControlStore";
import { bomMatRowStyle, bomTableStyles } from "./bomTableStyles";
import { ZBomPositionRow } from "../../../BomTypes";
import { BomMaterialRow } from "./BomMaterialRow";
import { BomColorRow } from "./BomColorRow";
import { PosColumn } from "../buildPositionsColumns";
import styles from "./BomPositionsTable.module.less";

interface PropsBomPositionsTable {
  store: BomControlStore;
}

export const BomPositionsTable: React.FC<PropsBomPositionsTable> = observer(
  ({ store }) => {
    const { positionColumns, positionStore } = store;
    const { tableRows } = positionStore;
    return (
      <div className={styles.table} style={bomTableStyles(positionColumns)}>
        {tableRows.map((row) => (
          <React.Fragment key={row.key}>
            {row.type === "material" && (
              <BomMaterialRow
                matRow={row}
                columns={positionColumns}
                collapsed={positionStore.isCollapsed(row.key)}
                toggle={() => positionStore.toggle(row.key)}
              />
            )}
            {row.type === "color" && (
              <BomColorRow
                colorRow={row}
                columns={positionColumns}
                collapsed={positionStore.isCollapsed(row.key)}
                toggle={() => positionStore.toggle(row.key)}
              />
            )}
            {row.type === "position" && (
              <PositionRow row={row.row} columns={positionColumns} />
            )}
          </React.Fragment>
        ))}
        {tableRows.length === 0 && (
          <div style={bomMatRowStyle(positionColumns)} data-first>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
    );
  },
);

interface PropsPositionRow {
  columns: PosColumn[];
  row: ZBomPositionRow;
}
const PositionRow: React.FC<PropsPositionRow> = (props) => {
  const { columns, row } = props;
  return columns.map(({ key, render }, colIndex) => (
    <div key={key} data-first={colIndex === 0 ? true : undefined}>
      {render(row)}
    </div>
  ));
};
