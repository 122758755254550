// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__frame--ZR_00 {
  display: grid;
  grid-template-columns: 110px 1fr;
  column-gap: 16px;
  height: 100%;
  overflow: hidden;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__frame--ZR_00 .src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__nav--JH4hr {
  height: 100%;
  overflow-y: auto;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__frame--ZR_00 .src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__main--hcBY2 {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__tools--NV2rS {
  padding: 2px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__tools--NV2rS .src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__title--H8SRS {
  flex: 1;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__header--mbf4k {
  margin-top: 4px;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__content--LDtdo {
  flex: 1;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom/BomControl/BomPositions/BomPositions.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gCAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;AACJ;AANA;EAQQ,YAAA;EACA,gBAAA;AACR;AAVA;EAYQ,gBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AACR;AAEA;EACI,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AAAJ;AALA;EAOQ,OAAA;AACR;AAEA;EACI,eAAA;AAAJ;AAEA;EACI,OAAA;EACA,gBAAA;AAAJ","sourcesContent":[".frame {\n    display: grid;\n    grid-template-columns: 110px 1fr;\n    column-gap: 16px;\n    height: 100%;\n    overflow: hidden;\n\n    .nav {\n        height: 100%;\n        overflow-y: auto;\n    }\n    .main {\n        overflow: hidden;\n        height: 100%;\n        display: flex;\n        flex-direction: column;\n    }\n}\n.tools {\n    padding: 2px 16px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 16px;\n    .title {\n        flex: 1;\n    }\n}\n.header {\n    margin-top: 4px;\n}\n.content {\n    flex: 1;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frame": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__frame--ZR_00`,
	"nav": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__nav--JH4hr`,
	"main": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__main--hcBY2`,
	"tools": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__tools--NV2rS`,
	"title": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__title--H8SRS`,
	"header": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__header--mbf4k`,
	"content": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositions-module__content--LDtdo`
};
export default ___CSS_LOADER_EXPORT___;
