// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsChat-ChatForm-ChatForm-module__box--cF0V8 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 15px;
}
.src-businessServices-services-bsChat-ChatForm-ChatForm-module__header--v6W0W {
  justify-content: space-between;
}
.src-businessServices-services-bsChat-ChatForm-ChatForm-module__headerInfo--Gfcaf {
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsChat-ChatForm-ChatForm-module__form--pJcb7 {
  max-width: 956px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsChat/ChatForm/ChatForm.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;EACA,SAAA;AACF;AAEA;EACE,8BAAA;AAAF;AAGA;EACE,aAAA;EACA,sBAAA;AADF;AAIA;EACE,gBAAA;AAFF","sourcesContent":[".box {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n  gap: 15px;\n}\n\n.header {\n  justify-content: space-between;\n}\n\n.headerInfo {\n  display: flex;\n  flex-direction: column;\n}\n\n.form {\n  max-width: 956px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsChat-ChatForm-ChatForm-module__box--cF0V8`,
	"header": `src-businessServices-services-bsChat-ChatForm-ChatForm-module__header--v6W0W`,
	"headerInfo": `src-businessServices-services-bsChat-ChatForm-ChatForm-module__headerInfo--Gfcaf`,
	"form": `src-businessServices-services-bsChat-ChatForm-ChatForm-module__form--pJcb7`
};
export default ___CSS_LOADER_EXPORT___;
