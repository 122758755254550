import React from "react";
import confirm from "antd/es/modal/confirm";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { onError } from "src/common/onError";
import { ChatListPageStore } from "../../ChatListPageStore";
import { ZChatInfo } from "../../bsChat.types";

type PropsDeleteChatButton = {
  chatInfo: ZChatInfo;
  pageStore: ChatListPageStore;
};

export const DeleteChatButton: React.FC<PropsDeleteChatButton> = ({
  chatInfo,
  pageStore,
}) => {
  const handleDelete = () => {
    const modal = confirm({
      title: "Удаление чата",
      content: <div>вы дейстительно хотите удалить чат?</div>,
      okButtonProps: { danger: true },
      onOk: () => {
        modal.update({ okButtonProps: { loading: true } });
        pageStore
          .deleteChat(chatInfo.id)
          .then(() => modal.destroy())
          .catch(onError)
          .finally(() => {
            modal.update({ okButtonProps: { loading: false } });
          });
      },
    });
  };
  return (
    <Button type="text" danger onClick={handleDelete}>
      <DeleteOutlined />
      Удалить чат
    </Button>
  );
};
