import * as React from "react";
import { ColumnPoor } from "src/components/tables/ColumnPoor";
import { Button, Checkbox } from "antd";
import { createItemView } from "src/common/attrView/createItemView/createItemView";
import { getViewInfo } from "src/common/attrView";
import { EditOutlined } from "@ant-design/icons";
import {
  BomMaterialCell,
  BomPositionMatImage,
  BomRowSelect,
  BomSupplierCell,
} from "./BomPositionsTable/BomPositionTableRow";
import { BomObjectsMap } from "../BomObjectsMap";
import { ZBomPositionRow, ZBomSettings } from "../../BomTypes";

// Колонки используются отдельно для вывода заголовка (он закреплён) и ячеек (они скроллируются)
// При этом, они должны совпасть. Поэтому ширина может быть в px или в fr, но не auto

export type PosColumn = Omit<ColumnPoor, "width"> & {
  render(row: ZBomPositionRow): React.ReactNode;
  width: string;
};
export interface PositionCtrl {
  toggleSelect(key: number): void;
  isSelected(key: number): boolean;
  readonly isSelectedAll: "all" | "none" | "partial";
  toggleAll(): void;
  startEdit(row: ZBomPositionRow): void;
}

export const buildPositionsColumns = (
  settings: ZBomSettings,
  objMap: BomObjectsMap,
  ctrl: PositionCtrl,
  typesMap: Record<number, string>,
): PosColumn[] => [
  {
    key: "select",
    title: (
      <Checkbox
        checked={ctrl.isSelectedAll === "all"}
        indeterminate={ctrl.isSelectedAll === "partial"}
        onChange={() => ctrl.toggleAll()}
      />
    ),
    visibility: "alwaysVisible",
    width: "48px",
    render: (row) => <BomRowSelect row={row} objMap={objMap} ctrl={ctrl} />,
  },
  // {
  //     key: "matType",
  //     title: "Тип материала",
  //     width: "1fr",
  //     render: (row) => getPosMatType(row, objMap).label,
  // },
  // {
  //     key: "colorName",
  //     title: "Цвет",
  //     width: "1fr",
  //     render: (row) => getPosColorName(row, objMap),
  // },
  {
    key: "matColor",
    title: "Изображение",
    width: "120px",
    render: (row) => <BomPositionMatImage row={row} objMap={objMap} />,
  },
  {
    key: "material",
    title: "Наименование материала",
    width: "1fr",
    render: (row) => <BomMaterialCell row={row} objMap={objMap} />,
  },
  {
    key: "supplier",
    title: "Поставщик",
    width: "1fr",
    render: (row) => <BomSupplierCell row={row} objMap={objMap} />,
  },
  ...settings.optionalAttributes.map(({ attributeId }) => {
    const attr = objMap.posAttrs[attributeId];
    return {
      key: `opt${attributeId}`,
      title: attr?.name ?? `PosAttr ${attributeId}`,
      width: "1fr",
      render: (row: ZBomPositionRow) =>
        !!attr &&
        createItemView(
          attr,
          typesMap,
          row.bomPositionEntity.attributeValues.find(
            ({ attributeId: id }) => id === attributeId,
          )?.values ?? [],
          // TODO: желательно это вычислить заранее и поместить в objMap
          getViewInfo(attr.viewType),
        ),
    };
  }),
  {
    key: "tools",
    title: " ",
    width: "64px",
    render: (row) => (
      <Button
        type="text"
        icon={<EditOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          ctrl.startEdit(row);
        }}
      />
    ),
  },
];
