// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomColorRow-BomColorRow-module__colorRow--cbP9O {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding-left: 48px !important;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom/BomControl/BomPositions/BomPositionsTable/BomColorRow/BomColorRow.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;EACA,6BAAA;AACJ","sourcesContent":[".colorRow {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 16px;\n    padding-left: 48px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorRow": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomColorRow-BomColorRow-module__colorRow--cbP9O`
};
export default ___CSS_LOADER_EXPORT___;
