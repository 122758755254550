import { ZAttrMeta } from "src/types/ZAttrMeta";
import { z } from "zod";
import { ZAttrViewInfo } from "../zAttrViewInfo";

export const zTagModeDictRef = z.enum(["standart", "tag", "tagsMulti"]);
export type ZTagModeDictRef = z.infer<typeof zTagModeDictRef>;

export const zTagModeDictRefMeta = z.object({
  tagsColorMap: z.record(z.string(), z.string()).optional(),
  tagColor: z.string().optional(),
});

export type ZTagModeDictRefMeta = z.infer<typeof zTagModeDictRefMeta>;

export const zDictRef = z.object({
  view: z.literal("DictRef"),
  mode: zTagModeDictRef.optional(),
  modeMeta: zTagModeDictRefMeta.optional(),
});

export type ZDictRef = z.infer<typeof zDictRef>;

export const makeDictRefProps = (
  cmp: ZDictRef | undefined,
  attrMeta: ZAttrMeta,
  values: string[],
  viewInfo: ZAttrViewInfo | undefined,
) => ({
  refId: Number(attrMeta.referenceId),
  values,
  viewInfo,
  viewProps: cmp,
});
