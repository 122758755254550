import { loadEntity, loadObject } from "src/pages/EntityCardPage/apiEntityCard";
import { AttrTypeName } from "src/types/AttrType";
import { zAttribute, ZAttribute } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import { ZGroup } from "src/types/ZGroup";
import { ZObjectItem } from "src/types/ZObjectItem";
import { langHdr } from "src/lang/langHdr";
import { ZOption } from "src/types/ZOption";
import { t } from "i18next";
import { apiObjUrl } from "./apiUrl";
import { rest } from "./rest";

type ParamsEntityName = {
  entity: ZEntity;
  object: ZObjectItem;
  typesMap: Record<number, string>;
  level?: number;
};

const maxLevel = 3;

export const getEntityName = async (
  params: ParamsEntityName,
): Promise<string> => {
  const { entity, object, typesMap, level = 0 } = params;
  const attr = findNameAttr(object);
  if (!attr) return t("Instance of object", { name: object.name });
  const field = entity.attributeValues.find(
    ({ attributeId }) => attributeId === attr.id,
  );
  const singleValue = field?.values?.[0];
  const typeName = typesMap[attr.valueType];
  if (singleValue) {
    const { referenceId } = attr;
    if (
      typeName === AttrTypeName.dictSingle ||
      typeName === AttrTypeName.dictMulti
    ) {
      if (!referenceId) return "Bad reference";
      const options = await loadRefOptions(referenceId);
      const refValue = options.find(({ value }) => value === singleValue);
      if (!refValue) return "Bad option";
      return refValue.label;
    }
    if (typeName === AttrTypeName.object) {
      if (level >= maxLevel) return `Max Level = ${maxLevel}`;
      if (!referenceId) return "Bad reference";
      const [subObject, subEntity] = await Promise.all([
        loadObject(referenceId, { translate: true }),
        loadEntity(singleValue, { translate: true }),
      ]);
      return getEntityName({
        entity: subEntity,
        object: subObject,
        typesMap,
        level: level + 1,
      });
    }
  }
  return singleValue || t("Instance of object", { name: object.name });
};

export const findNameAttr = (object: ZObjectItem): ZAttribute | undefined =>
  findInAttrs(object.attributes) || findInGroups(object.groups);

const findInAttrs = (attrList?: ZAttribute[] | null): ZAttribute | undefined =>
  attrList?.find(({ fieldKey }) => fieldKey === "NAME");

const findInGroup = (group: ZGroup): ZAttribute | undefined =>
  findInAttrs(group.attributes) || findInGroups(group.groups);

const findInGroups = (groups?: ZGroup[] | null): ZAttribute | undefined => {
  if (groups) {
    // eslint-disable-next-line no-restricted-syntax
    for (const group of groups) {
      const attr = findInGroup(group);
      if (attr) return attr;
    }
  }
  return undefined;
};

export const loadRefOptions = async (
  referenceId: number,
): Promise<ZOption[]> => {
  const resp = await rest.get(apiObjUrl(`/attributes/${referenceId}`), {
    headers: langHdr(),
  });
  const ref = zAttribute.parse(resp.data);
  return (
    ref.values?.map((a) => ({
      label: a.value || String(a.id),
      value: String(a.id),
    })) ?? []
  );
};
