import * as React from "react";
import { BusinessServiceDescriptor } from "src/businessServices/BusinessServiseDescriptor";
import { BomSettings } from "./BomSettings";
import { loadBomSettings, saveBomSettings } from "./apiBom";
import { ZBomSettings, zBomSettings } from "./BomTypes";
import { BomControl } from "./BomControl";

export const bsBom: BusinessServiceDescriptor = {
  key: "BOM",
  viewer(info, data) {
    return <BomControl info={info} entityData={data} />;
  },
  renderSettings(info, store) {
    return <BomSettings info={info} typesMap={store.attrTypesDict} />;
  },
  async loadSettings(info) {
    const settings = await loadBomSettings(info.id);
    return settings ?? ({ optionalAttributes: [] } as unknown as ZBomSettings);
  },
  async saveSettings(info, values) {
    const settings = zBomSettings.parse(values);
    await saveBomSettings(info.id, settings);
  },
};
