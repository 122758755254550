import * as React from "react";
import { Pagination } from "antd";
import { observer } from "mobx-react-lite";
import { TableStore } from "../tables/TableStore";
import { AColumn, AsyncTable } from "../tables/AsyncTable";
import styles from "./ScrollableTable.module.less";

interface PropsScrollableTable<TRow, TFilters extends object> {
  store: TableStore<TRow, TFilters>;
  columns: AColumn<TRow>[];
  title?: React.ReactNode;
  extraTitle?: React.ReactNode;
  tools?: React.ReactNode;
  onRowClick?(row: TRow): void;
  showTotal?(count: number): string;
}
// TODO: Пока нет фильтров и настроек, т.к. они пока не использовались

export const ScrollableTable = observer(
  <TRow extends {}, TFilters extends object>(
    props: PropsScrollableTable<TRow, TFilters>,
  ): React.ReactElement => {
    const { store, columns, title, extraTitle, tools, onRowClick, showTotal } =
      props;
    const refTbl = React.useRef<HTMLDivElement>(null);
    const [tblHeight, setTblHeight] = React.useState(400);
    React.useEffect(() => {
      const setHeight = () => {
        const { current } = refTbl;
        if (current) {
          let h = current.clientHeight;
          const thead = current.querySelector("thead");
          if (thead) h -= thead.offsetHeight;
          setTblHeight(h);
        }
      };
      setHeight();
      const onResize = () => {
        setHeight();
      };
      window.addEventListener("resize", onResize);
      return () => {
        window.removeEventListener("resize", onResize);
      };
    }, []);

    return (
      <div className={styles.frame}>
        <div className={styles.header}>
          {title && <div className={styles.title}>{title}</div>}
          {extraTitle}
          <div className={styles.tools}>{tools}</div>
        </div>
        <div className={styles.table} ref={refTbl}>
          <AsyncTable<TRow, TFilters>
            store={store}
            columns={columns}
            onRowClick={onRowClick}
            scroll={{ y: tblHeight }}
            usePagination={false}
          />
        </div>
        <div className={styles.paginator}>
          <Pagination
            current={store.page + 1}
            pageSize={store.pageSize}
            showSizeChanger
            total={store.totalItems}
            size="small"
            showTotal={showTotal}
            onChange={(page, pageSize) => {
              store.setPage(page - 1);
              store.setPageSize(pageSize);
            }}
          />
        </div>
      </div>
    );
  },
);
