import React, { useEffect, useState } from "react";
import { ZPersonRow } from "src/types/ZPersonRow";
import { ZObjectItem } from "src/types/ZObjectItem";
import { ZRolesViewAtts } from "src/common/roles/ZRolesViewAtts";
import { FileImageFilled } from "@ant-design/icons";
import { composeEntityLabel } from "src/common/entity/composeEntityLabel/composeEntityLabel";
import { onError } from "src/common/onError";
import { AttrTypeName } from "src/types/AttrType";
import { Spin } from "antd";
import { classNames } from "src/common/classNames";
import { ZAttrViewInfo } from "src/common/attrView/zAttrViewInfo";
import styles from "./PersonLabel.module.less";

type PropsPersonLabel = {
  person: ZPersonRow;
  rolesViewAtts?: ZRolesViewAtts;
  rolesDict: Record<number, ZObjectItem>;
  viewInfo?: ZAttrViewInfo;
};

export const PersonLabel: React.FC<PropsPersonLabel> = ({
  person,
  rolesViewAtts,
  rolesDict,
  viewInfo,
}) => {
  const [label, setLabel] = useState<React.JSX.Element | React.ReactNode[]>();
  const [loading, setLoading] = useState(false);

  const init = async () => {
    try {
      setLoading(true);
      const [roleId] = person.roleIds;
      const role = rolesDict[Number(roleId)];
      const viewAtts = rolesViewAtts?.[String(role?.id)];
      if (!viewAtts) return;
      const l = await composeEntityLabel(
        person,
        viewAtts,
        viewInfo,
        undefined,
        {
          separator: viewInfo?.appearance?.view?.separator ?? "comma",
          replace: { [AttrTypeName.image]: <FileImageFilled /> },
        },
      );
      setLabel(l);
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const overflow = viewInfo?.appearance?.view?.overflow;

  if (loading) return <Spin>...</Spin>;

  return (
    <div
      style={viewInfo?.styles ?? undefined}
      className={classNames([
        overflow ? styles[overflow] : styles.default,
        styles.box,
      ])}
    >
      {label}
    </div>
  );
};

PersonLabel.defaultProps = { rolesViewAtts: undefined, viewInfo: undefined };
