import { ZBomPositionRow } from "../../BomTypes";

export interface MaterialRow {
  type: "material";
  key: string;
  name: string;
  colors: ColorDict;
}

export interface ColorRow {
  type: "color";
  key: string;
  name: string;
  rows: ZBomPositionRow[];
}

interface PositionRow {
  type: "position";
  row: ZBomPositionRow;
  key: number;
}

export type PosTableRow = ColorRow | MaterialRow | PositionRow;

export type ColorDict = Record<string, ColorRow>;
export type MaterialDict = Record<string, MaterialRow>;

// В ключах нельзя использовать некоторые символы типа :@ т.к. они становятся id html-элементов
export const makeMatKey = (id: string) => `material_${id}`;
export const makeColorKey = (matKey: string, colorId: number | null) =>
  `${matKey}:color_${colorId}`;
