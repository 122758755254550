import React from "react";
import { useForm } from "antd/es/form/Form";
import { Button, Form, Input, Modal, Space } from "antd";
import { onError } from "src/common/onError";
import { EntityCardData } from "src/pages/EntityCardPage/EntityCardStore";
import { observer } from "mobx-react-lite";
import styles from "./ChatSettingsMenu.module.less";
import { RCNewChatFormData } from "../../bsChat.types";
import { PersonSelectCompex } from "../../NewChatButton/PersonSelectCompex";

type PropsChatSettingsModal = {
  onClose: () => void;
  submit: (data: RCNewChatFormData) => Promise<unknown>;
  cardData: EntityCardData;
  modalTitle: string;
  okBtnText: string;
  initial?: RCNewChatFormData;
};

export const ChatSettingsModal: React.FC<PropsChatSettingsModal> = observer(
  ({ cardData, modalTitle, okBtnText, initial, onClose, submit }) => {
    const [form] = useForm();

    const handleCreate = (data: RCNewChatFormData) => {
      submit(data)
        .then(() => {
          form.resetFields();
          onClose();
        })
        .catch(onError);
    };
    return (
      <Modal open title={modalTitle} footer={null} onCancel={onClose}>
        <Form<RCNewChatFormData>
          form={form}
          layout="vertical"
          className={styles.form}
          onFinish={handleCreate}
          initialValues={initial}
        >
          <Form.Item name="name" label="Название" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Участники"
            name="userIds"
            rules={[{ required: true }]}
          >
            <PersonSelectCompex attributes={cardData.object.attributes} />
          </Form.Item>
          <Space>
            <Form.Item>
              <Button htmlType="button" onClick={onClose}>
                Отменить
              </Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                {okBtnText}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    );
  },
);
