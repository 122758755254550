import * as React from "react";
import { observer } from "mobx-react-lite";
import { ObjectServiceExt } from "src/businessServices/businessServises.types";
import { LoaderBox } from "src/components/LoaderBox";
import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { BomControlStore, bomControlStore } from "./BomControlStore";
import { ZBomSettings } from "../BomTypes";
import { BomSummary } from "./BomSummary";
import styles from "./BomControl.module.less";
import { BomPositions } from "./BomPositions";

interface PropsBomControl {
  info: ObjectServiceExt;
  entityData: EntityCardDataExt;
}

export const BomControl: React.FC<PropsBomControl> = observer(
  ({ info, entityData }) => {
    React.useEffect(() => {
      bomControlStore.init(info.id, entityData);
    }, [info.id]);
    return (
      <LoaderBox
        remoteData={bomControlStore.settings}
        drawReady={(data) => (
          <BomsView store={bomControlStore} settings={data} />
        )}
      />
    );
  },
);

interface PropsBomsView {
  store: BomControlStore;
  settings: ZBomSettings;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BomsView: React.FC<PropsBomsView> = observer(({ store, settings }) => {
  const items: TabsProps["items"] = [
    { key: "null", label: "Сводка" },
    ...store.bomTabs,
  ];
  const { curBomId, curBomKey } = store;
  return (
    <div className={styles.mainBox}>
      <Tabs
        items={items}
        type="card"
        className={styles.tabsBox}
        activeKey={curBomKey}
        onChange={(key: string) => store.changeTab(key)}
      />
      <div className={styles.content}>
        {curBomId ? (
          <BomPositions store={store} />
        ) : (
          <BomSummary store={store} />
        )}
      </div>
    </div>
  );
});
