import * as React from "react";
import { observer } from "mobx-react-lite";
import { classNames } from "src/common/classNames";
import { BomControlStore } from "../../BomControlStore";
import { bomTableStyles } from "./bomTableStyles";
import styles from "./BomPositionsTable.module.less";

interface PropsBomPositionsTableHeader {
  store: BomControlStore;
}

export const BomPositionsTableHeader: React.FC<PropsBomPositionsTableHeader> =
  observer(({ store }) => {
    const { positionColumns } = store;
    return (
      <div
        className={classNames([styles.table, styles.header])}
        style={bomTableStyles(positionColumns)}
      >
        {positionColumns.map(({ key, title }, i) => (
          <div key={key} data-first={i === 0 ? true : undefined}>
            <div className={styles.headerCell}>{title}</div>
          </div>
        ))}
      </div>
    );
  });
