// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionsTable-module__table--UQvel {
  display: grid;
  grid-auto-rows: auto;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionsTable-module__table--UQvel > div {
  padding: 10px 16px;
  border-bottom: thin solid #E1E3E6;
  border-right: thin solid #E1E3E6;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionsTable-module__table--UQvel > div[data-first] {
  border-left: thin solid #E1E3E6;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionsTable-module__table--UQvel.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionsTable-module__header--ZXoXe > div {
  border-top: thin solid #E1E3E6;
}
.src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionsTable-module__table--UQvel .src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionsTable-module__headerCell--QLSFZ {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  color: #767C84;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom/BomControl/BomPositions/BomPositionsTable/BomPositionsTable.module.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,oBAAA;AADJ;AAEI;EACI,kBAAA;EACA,iCAAA;EACA,gCAAA;AAAR;AACQ;EACI,+BAAA;AACZ;AAEI;EACI,8BAAA;AAAR;AAZA;EAeQ,aAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,cAAA;EACA,6CAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAAR","sourcesContent":["@border: thin solid #E1E3E6;\n\n.table {\n    display: grid;\n    grid-auto-rows: auto;\n    & > div {\n        padding: 10px 16px;\n        border-bottom: @border;\n        border-right: @border;\n        &[data-first] {\n            border-left: @border;\n        }\n    }\n    &.header > div {\n        border-top: @border;\n    }\n    .headerCell {\n        display: flex;\n        flex-direction: row;\n        height: 100%;\n        align-items: center;\n        color: #767C84;\n        font-feature-settings: 'liga' off, 'clig' off;\n        font-size: 12px;\n        font-style: normal;\n        font-weight: 500;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionsTable-module__table--UQvel`,
	"header": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionsTable-module__header--ZXoXe`,
	"headerCell": `src-businessServices-services-bsBom-BomControl-BomPositions-BomPositionsTable-BomPositionsTable-module__headerCell--QLSFZ`
};
export default ___CSS_LOADER_EXPORT___;
