import * as React from "react";
import { MaterialRow } from "../../BomPositionRowTypes";
import { bomMatRowStyle } from "../bomTableStyles";
import { PosColumn } from "../../buildPositionsColumns";
import styles from "./BomMaterialRow.module.less";
import { ExpandButton } from "../ExpandButton";

interface PropsBomMaterialRow {
  matRow: MaterialRow;
  columns: PosColumn[];
  collapsed: boolean;
  toggle(): void;
}

export const BomMaterialRow: React.FC<PropsBomMaterialRow> = (props) => {
  const { matRow, columns, collapsed, toggle } = props;
  return (
    <div
      style={bomMatRowStyle(columns)}
      data-first
      className={styles.materialRow}
      id={matRow.key}
    >
      <ExpandButton collapsed={collapsed} onClick={toggle} />
      <span>{matRow.name}</span>
    </div>
  );
};
