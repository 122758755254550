import * as React from "react";
import { Select } from "antd";
import { ZIdName, idNameFieldNames } from "src/types/ZIdName";
import { onError } from "src/common/onError";

interface PropsSelectObject {
  value?: number;
  onChange?(newValue?: number): void;
  disabled?: boolean;
  loadObjectOptions(): Promise<ZIdName[]>;
}

const filterOption = (input: string, option?: ZIdName) =>
  (option?.name ?? "").toLowerCase().includes(input.toLowerCase());

export const SelectObject: React.FC<PropsSelectObject> = (props) => {
  const { loadObjectOptions, value, onChange, disabled } = props;
  const onChangeObj = (v?: number) => {
    onChange?.(v);
  };

  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<ZIdName[]>([]);
  React.useEffect(() => {
    setLoading(true);
    loadObjectOptions()
      .then(setOptions)
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);
  return (
    <Select<number, ZIdName>
      allowClear
      showSearch
      placeholder="Выберите объект"
      filterOption={filterOption}
      fieldNames={idNameFieldNames}
      options={options}
      value={value}
      onChange={onChangeObj}
      placement="bottomLeft"
      disabled={disabled}
      loading={loading}
    />
  );
};

SelectObject.defaultProps = {
  value: undefined,
  onChange: undefined,
  disabled: false,
};
