import { observer } from "mobx-react-lite";
import * as React from "react";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { TShapeForm } from "src/components/TShapeForm/TShapeForm";
import { Form } from "antd";
import { entity2ed } from "src/pages/EntityCardPage/apiEntityCard";
import { BomControlStore } from "../BomControlStore";

interface PropsBomSummaryEditor {
  store: BomControlStore;
}

export const BomSummaryEditor: React.FC<PropsBomSummaryEditor> = observer(
  ({ store }) => {
    const [form] = Form.useForm();
    const { editor } = store;
    React.useEffect(() => {
      if (editor) {
        form.resetFields();
        if (editor.row) {
          form.setFieldsValue(entity2ed(editor.row));
        }
      }
    }, [editor]);
    if (!editor) return null;
    return (
      <ModalVertFixed
        open={!!editor}
        onCancel={() => store.closeEditor()}
        footer={null}
        width="80vw"
        centered
      >
        <TShapeForm
          title={editor?.title}
          form={form}
          formProps={{
            name: "BomPosition",
            block: store.bomBlock,
            store: store.formStore,
            submitText: editor.submitText,
            submit: editor.submit,
            onSuccess: () => store.closeEditor(),
          }}
        />
      </ModalVertFixed>
    );
  },
);
