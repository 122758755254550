import * as React from "react";
import { CtrlResizable } from "src/components/tables/TableStore";
import { ColumnPoor } from "src/components/tables/ColumnPoor";
import styles from "./ResizerStd.module.less";

type PropsResizerStd = {
  column: ColumnPoor;
  store: CtrlResizable;
  startWidth?: number;
};

const minColWidth = 50;

export const ResizerStd: React.FC<PropsResizerStd> = (props) => {
  const { column, store, startWidth } = props;

  React.useEffect(() => {
    if (column.width !== undefined && startWidth) return;
    if (column.width === undefined) {
      store.setColumnWidth(column.key, startWidth);
    } else {
      store.setColumnWidth(column.key, column.width);
    }
  }, [startWidth]);

  const resizeColumn =
    (key: string) => (event: React.MouseEvent<HTMLDivElement>) => {
      const startX = event.clientX;
      const handleMouseMove = (e: MouseEvent) => {
        const currentWidth = Math.max(
          minColWidth,
          (startWidth || 0) + (e.clientX - startX),
        );
        store.setColumnWidth(key, currentWidth);
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

  return (
    store.resizableColumns && (
      <div
        className={styles.columnResizeHandle}
        onMouseDown={resizeColumn(column.key)}
        role="presentation"
      />
    )
  );
};

ResizerStd.defaultProps = {
  startWidth: undefined,
};
