import {
  DeleteOutlined,
  DownloadOutlined,
  PictureOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Button, UploadFile, Image, Spin, Space } from "antd";
import React from "react";
import styles from "./ImageCarouselInner.module.less";

interface PropsImageCarouselInner {
  image: UploadFile | undefined;
  onDelete: () => void;
  disabled: boolean;
  height?: number;
}

export const ImageCarouselInner: React.FC<PropsImageCarouselInner> = (
  props,
) => {
  const { image, onDelete, height, disabled } = props;
  return (
    <div className={styles.imageContainer} style={{ height: `${height}px` }}>
      {image?.status === "uploading" && <Spin />}
      {image?.status === "done" && (image?.url || image?.thumbUrl) && (
        <Image
          className={styles.image}
          src={image?.url || image?.thumbUrl}
          preview={{
            // eslint-disable-next-line react/no-unstable-nested-components
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
              },
            ) => (
              <Space size={24} className={styles.toolbar}>
                {image?.url && (
                  <Button
                    icon={<DownloadOutlined />}
                    href={image?.url}
                    className={styles.downloadButton}
                  />
                )}
                <RotateLeftOutlined onClick={onRotateLeft} />
                <RotateRightOutlined onClick={onRotateRight} />
                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                {!disabled && <DeleteOutlined onClick={onDelete} />}
              </Space>
            ),
          }}
        />
      )}
      {image?.status === "error" && (
        <div className={styles.imageError}>
          <PictureOutlined />
        </div>
      )}
    </div>
  );
};

ImageCarouselInner.defaultProps = {
  height: undefined,
};
