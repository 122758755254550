// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom-BomSettings-BomSettings-module__box--iirxv {
  width: 856px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.src-businessServices-services-bsBom-BomSettings-BomSettings-module__threeCols--XStYw {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
}
.src-businessServices-services-bsBom-BomSettings-BomSettings-module__formListBox--K24uy {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.src-businessServices-services-bsBom-BomSettings-BomSettings-module__listRow--nhSj4 {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom/BomSettings/BomSettings.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACA;EACI,aAAA;EACA,kCAAA;EACA,SAAA;AACJ;AACA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AACA;EACI,aAAA;EACA,+BAAA;EACA,SAAA;AACJ","sourcesContent":[".box {\n    width: 856px;\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n}\n.threeCols {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    gap: 12px;\n}\n.formListBox {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n.listRow {\n    display: grid;\n    grid-template-columns: 1fr auto;\n    gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsBom-BomSettings-BomSettings-module__box--iirxv`,
	"threeCols": `src-businessServices-services-bsBom-BomSettings-BomSettings-module__threeCols--XStYw`,
	"formListBox": `src-businessServices-services-bsBom-BomSettings-BomSettings-module__formListBox--K24uy`,
	"listRow": `src-businessServices-services-bsBom-BomSettings-BomSettings-module__listRow--nhSj4`
};
export default ___CSS_LOADER_EXPORT___;
