import React from "react";
import axios from "axios";
import { z } from "zod";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { UploadFile, Upload, Button } from "antd";
import { UploadChangeParam, UploadProps } from "antd/es/upload";
import { loadFileInfo } from "src/common/apiFiles";
import { apiFilesUrl } from "src/common/apiUrl";
import { AttrUploaderProps } from "src/common/attrEdit/components";
import { onError as onCommonError } from "src/common/onError";
import { classNames } from "src/common/classNames";
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadRequestOption } from "rc-upload/lib/interface";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import styles from "./PlmUploader.module.less";
import { urlForDownload } from "./imgSrc";

type PropsPlmUploader = AttrUploaderProps &
  Omit<UploadProps, "fileList"> & {
    value?: string[];
    onChange?(v: string[] | undefined): void;
    isImage: boolean;
  };

export const zUploadResult = z.object({
  fileName: z.string(),
  filePath: z.string(),
});

export type TFilesDict = Record<string, UploadFile>;

export const makeFileItem = (
  isImage: boolean,
  uid: string,
  name?: string,
  size?: number,
): UploadFile => ({
  uid,
  size,
  name: name ?? uid,
  url: urlForDownload(isImage, uid),
  status: "done",
});

export type UploadMethods = {
  upload: ({ file, onSuccess, onError }: UploadRequestOption) => void;
  beforeUpload: (file: UploadFile, uploadFileList: UploadFile[]) => void;
};

export const onFileListChanged = async (
  newFileList: UploadFile[],
  onChange?: (v: string[] | undefined) => void,
) => {
  const newValues = newFileList
    .filter(({ status }) => status === "done")
    .map(({ uid }) => uid);
  onChange?.(newValues);
};

export const makeUploadMethods = (
  isImage: boolean,
  fileList: UploadFile[],
  maxSize?: number,
): UploadMethods => {
  const upload = async ({ file, onSuccess, onError }: UploadRequestOption) => {
    try {
      const resp = await axios.post(
        apiFilesUrl(""),
        { file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
          params: {
            fileType: isImage ? "IMAGE" : undefined,
          },
        },
      );
      const result = zUploadResult.parse(resp.data);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      if (onError) {
        onError(error);
        onCommonError(error);
      }
    }
  };

  const calculateTotalSize = (files: UploadFile[]) =>
    files.reduce((total, file) => total + (file.size || 0), 0) / (1024 * 1024);

  const beforeUpload = async (
    file: UploadFile,
    uploadFileList: UploadFile[],
  ) => {
    const lastFileUid = uploadFileList[uploadFileList.length - 1]?.uid;
    try {
      if (maxSize) {
        const uploadTotalSize = calculateTotalSize([
          ...fileList,
          ...uploadFileList,
        ]);

        if (uploadTotalSize > maxSize) {
          throw Error(t("Total files size exceeds", { size: maxSize }));
        }
      }
      return true;
    } catch (error) {
      if (file.uid === lastFileUid) {
        onCommonError(error);
      }
      return Upload.LIST_IGNORE;
    }
  };

  return { upload, beforeUpload };
};

export const PlmUploader: React.FC<PropsPlmUploader> = (props) => {
  const {
    value,
    onChange,
    isImage,
    disabled,
    multiple,
    maxCount,
    maxSize,
    accept,
    ...uploadProps
  } = props;
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [dict, setDict] = React.useState<TFilesDict>({});
  const uploadMaxCount = (multiple ? maxCount : undefined) ?? 1;
  React.useEffect(() => {
    // Из dict можно взять те файлы, которые уже известны для данного компонента.
    // В момент загрузки новых данных требуется загружать информацию о каждом файле.

    const guids: string[] = value ?? [];
    setFileList(
      guids.map((uid) => {
        const file = dict[uid];
        return file ?? makeFileItem(isImage, uid);
      }),
    );
    // Для всех, кого нет в словаре - загрузка инфы
    guids
      .filter((uid) => !dict[uid])
      .map((uid) =>
        loadFileInfo(uid)
          .then(({ fileName, size }) => {
            const file: UploadFile = makeFileItem(isImage, uid, fileName, size);
            // сохранить полученную инфу в кеше
            setDict((prev) => ({ ...prev, [uid]: file }));
            // заместить заглушку на нормальное имя
            setFileList((prev) =>
              prev.map((item) => (item.uid === uid ? file : item)),
            );
          })
          .catch(onCommonError),
      );
  }, [value]);

  const { upload, beforeUpload } = makeUploadMethods(
    isImage,
    fileList,
    maxSize,
  );

  const handleChange = async (info: UploadChangeParam) => {
    const { status, response } = info.file;
    setFileList(info.fileList);
    if (status === "done") {
      // eslint-disable-next-line no-param-reassign
      info.file.uid = response.filePath;
      setDict((prev) => ({
        ...prev,
        [response.filePath]: info.file as RcFile,
      }));
      await onFileListChanged(info.fileList, onChange);
      setFileList(info.fileList);
    }
  };

  const onRemove = (file: UploadFile) => {
    // Удалять файл из хранилища тут преждевременно.
    // Т.к. изменение на форме еще не гарантирует что будет выполнен submit.
    onFileListChanged(
      fileList.filter(({ uid }) => uid !== file.uid),
      onChange,
    );
  };

  return (
    <>
      <Upload
        className={classNames([
          [!isImage, styles.plmFileUploader],
          [fileList.length === 0, styles.plmImgUploaderStart],
        ])}
        {...uploadProps}
        fileList={fileList}
        customRequest={async (options) => upload(options)}
        onChange={handleChange}
        onRemove={onRemove}
        disabled={disabled}
        listType={isImage ? "picture-card" : undefined}
        maxCount={uploadMaxCount}
        multiple={multiple}
        beforeUpload={beforeUpload}
        accept={accept}
      >
        {(fileList.length === 0 || fileList.length < uploadMaxCount) &&
          (!isImage ? (
            <Button
              disabled={disabled}
              className={styles.fileUploadButton}
              icon={<UploadOutlined />}
            >
              {t("Upload")}
            </Button>
          ) : (
            <button
              disabled={disabled}
              className={styles.imageUploadButton}
              type="button"
            >
              <PlusOutlined />
              <div>{t("Upload")}</div>
            </button>
          ))}
      </Upload>
      <UploadInfo maxCount={uploadMaxCount} accept={accept} maxSize={maxSize} />
    </>
  );
};

PlmUploader.defaultProps = {
  value: undefined,
  onChange: undefined,
};

interface PropsUploadInfo {
  maxCount: number;
  accept?: string;
  maxSize?: number;
}

export const UploadInfo: React.FC<PropsUploadInfo> = (props) => {
  const { maxCount, accept, maxSize } = props;
  return (
    <div className={styles.uploadInfo}>
      <div>{accept && t("Format: value", { value: accept })}</div>
      <div>{maxCount && t("Maximum number: count", { count: maxCount })}</div>
      <div>{maxSize && t("Maximum total size:", { size: maxSize })}</div>
    </div>
  );
};

UploadInfo.defaultProps = {
  accept: undefined,
  maxSize: undefined,
};
