// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectCopyModal-ObjectCopyModal-module__form--UpAe8 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectCopyModal-ObjectCopyModal-module__formContent--FqwQn {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow-y: hidden;
}
.src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectCopyModal-ObjectCopyModal-module__attrsBox--VMTMA {
  overflow-y: auto;
}
.src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectCopyModal-ObjectCopyModal-module__attrsBox--VMTMA label {
  display: flex;
  align-items: center;
}
.src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectCopyModal-ObjectCopyModal-module__attrsList--zQJvC {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/ObjectForm2/ObjectCopyModal/ObjectCopyModal.module.less"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;AAAJ;AAEA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,YAAA;EACA,kBAAA;AAAJ;AAEA;EACI,gBAAA;AAAJ;AADA;EAGQ,aAAA;EACA,mBAAA;AACR;AAEA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAAJ","sourcesContent":["\n.form {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n.formContent {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    height: 100%;\n    overflow-y: hidden;\n}\n.attrsBox {\n    overflow-y: auto;\n    label {\n        display: flex;\n        align-items: center;\n    }\n}\n.attrsList {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectCopyModal-ObjectCopyModal-module__form--UpAe8`,
	"formContent": `src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectCopyModal-ObjectCopyModal-module__formContent--FqwQn`,
	"attrsBox": `src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectCopyModal-ObjectCopyModal-module__attrsBox--VMTMA`,
	"attrsList": `src-pages-ManagementPage-Obj2Tab-forms-ObjectForm2-ObjectCopyModal-ObjectCopyModal-module__attrsList--zQJvC`
};
export default ___CSS_LOADER_EXPORT___;
