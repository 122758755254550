import React from "react";
import { classNames } from "src/common/classNames";
import { getGlobalParams } from "src/common/globalParams";
import { observer } from "mobx-react-lite";
import Sider from "antd/es/layout/Sider";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MessageFilled,
} from "@ant-design/icons";
import { Button, ConfigProvider, Spin } from "antd";
import { Link } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { ModalSupport } from "src/components/ModalSupport";
import { t } from "i18next";
import styles from "./LeftMenu.module.less";
import { LogoIcon } from "./icons/LogoIcon";
import { leftMenuStore as store } from "./leftMenuStore";
import { MenuItemsList } from "./MenuItemsList";

export const LeftMenu: React.FC = observer(() => {
  React.useEffect(() => {
    store.init();
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorLink: "#FFFFFF99",
          colorLinkHover: "#FFFFFF",
          colorLinkActive: "#FFFFFF",
        },
      }}
    >
      <Sider
        width={256}
        className={classNames([
          [store.menuState.menuActive, styles.leftMenuActive],
          styles.leftMenu,
        ])}
        trigger={null}
        collapsible
        collapsed={!store.menuState.menuActive}
        collapsedWidth={72}
      >
        <div className={styles.menuContent}>
          <Link className={styles.logoSection} to={makeUrl(PageUrl.home)}>
            <LogoIcon />
            <span className={styles.toggledText}>
              {getGlobalParams().title.toLowerCase()}
            </span>
          </Link>
          <Spin spinning={store.loading}>
            <div
              className={classNames([
                styles.itemsSection,
                [store.loading, styles.loaderBox],
              ])}
            >
              <MenuItemsList list={store.menuList} />
            </div>
          </Spin>
        </div>
        <div>
          <Button
            className={styles.toggleButton}
            onClick={() => store.showSupport()}
          >
            <MessageFilled />
            <span className={styles.toggledText}>{t("Tech support")}</span>
          </Button>
          <Button
            className={styles.toggleButton}
            onClick={() => store.onActiveToggle()}
          >
            {store.menuState.menuActive ? (
              <MenuUnfoldOutlined />
            ) : (
              <MenuFoldOutlined />
            )}
            <span className={styles.toggledText}>{t("Collapse menu")}</span>
          </Button>
        </div>
      </Sider>
      <ModalSupport />
    </ConfigProvider>
  );
});
