// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom-BomControl-BomSummary-BomsTable-module__tableBox--nx2ZF {
  flex: 1;
  overflow: hidden;
}
.src-businessServices-services-bsBom-BomControl-BomSummary-BomsTable-module__rowsClickable--bKQ6Y .ant-table-row-level-0 {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom/BomControl/BomSummary/BomsTable.module.less"],"names":[],"mappings":"AAAA;EACI,OAAA;EACA,gBAAA;AACJ;AACA;EACI,eAAA;AACJ","sourcesContent":[".tableBox {\n    flex: 1;\n    overflow: hidden;\n}\n.rowsClickable :global(.ant-table-row-level-0) {\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBox": `src-businessServices-services-bsBom-BomControl-BomSummary-BomsTable-module__tableBox--nx2ZF`,
	"rowsClickable": `src-businessServices-services-bsBom-BomControl-BomSummary-BomsTable-module__rowsClickable--bKQ6Y`
};
export default ___CSS_LOADER_EXPORT___;
