/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { BusinessServiceDescriptor } from "src/businessServices/BusinessServiseDescriptor";
import { ChatListPage } from "./ChatListPage";

export const bsChat: BusinessServiceDescriptor = {
  key: "chat",
  viewer(info, data) {
    return <ChatListPage data={data} info={info} />;
  },
  async loadSettings(info) {
    return info;
  },
  renderSettings(info, store) {
    return <>В разработке...</>;
  },
  async saveSettings(info, values) {
    // eslint-disable-next-line no-console
    console.log("save");
  },
};
