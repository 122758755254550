// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom-BomControl-BomSummary-BomSummary-module__bomSummary--ec8Su {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsBom-BomControl-BomSummary-BomSummary-module__header--Ih55x {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.src-businessServices-services-bsBom-BomControl-BomSummary-BomSummary-module__selectedCount--YkLdI {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom/BomControl/BomSummary/BomSummary.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;AACA;EACI,OAAA;AACJ","sourcesContent":[".bomSummary {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n.header {\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n}\n.selectedCount {\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bomSummary": `src-businessServices-services-bsBom-BomControl-BomSummary-BomSummary-module__bomSummary--ec8Su`,
	"header": `src-businessServices-services-bsBom-BomControl-BomSummary-BomSummary-module__header--Ih55x`,
	"selectedCount": `src-businessServices-services-bsBom-BomControl-BomSummary-BomSummary-module__selectedCount--YkLdI`
};
export default ___CSS_LOADER_EXPORT___;
