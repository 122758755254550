import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Form,
  FormRule,
  Input,
  InputRef,
  Spin,
  Tag,
  Tooltip,
  notification,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { delay } from "src/common/delay";
import { ZAttribute, ZAttributeValue } from "src/types/ZAttribute";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { t } from "i18next";
import { MultiLangFields } from "src/components/MultiLangFields";
import { Dict2TabStore } from "../Dict2TabStore";
import { newItemId } from "../GroupsTreeView/groupsTreeUtils";
import styles from "./AttrFields2.module.less";
import { EdDict } from "./EdDict";
import { ExtraTools } from "../../ExtraTools";

interface PropsAttrFields2 {
  store: Dict2TabStore;
  attr: ZAttribute | null | undefined;
}

const dictField = (key: keyof EdDict) => key;

export const AttrFields2: React.FC<PropsAttrFields2> = observer(
  ({ store, attr }) => {
    const ref1 = React.useRef<InputRef>(null);
    React.useEffect(() => {
      delay(10).then(() => ref1.current?.focus());
    }, [store.currentItem]);
    const form = Form.useFormInstance();
    const curValues: ZAttributeValue[] | null = Form.useWatch("values");
    React.useEffect(() => {
      if (curValues?.find(({ id }) => id === newItemId)) {
        attr?.values?.forEach(({ id }, i) => {
          form.setFieldValue(["values", i, "id"], id);
        });
      }
    }, [attr?.values]);
    const statusView = (index: number) => {
      const status = store.statuses[curValues?.[index]?.value ?? ""];
      if (status === "double")
        return <Tag icon={<ExclamationCircleOutlined />} color="warning" />;
      if (status === "new")
        return <Tag icon={<CheckCircleOutlined />} color="success" />;
      return undefined;
    };
    return (
      <Spin spinning={store.isContentLoading}>
        <div className={styles.vert}>
          <Form.Item
            name={dictField("name")}
            label="Название"
            rules={[trimRequired()]}
          >
            <Input ref={ref1} allowClear />
          </Form.Item>
          <div className={styles.twoCols}>
            <div className={styles.vert}>
              <h3>Значения</h3>
              <Form.List name={dictField("values")}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <React.Fragment key={field.key}>
                        <Form.Item
                          {...field}
                          name={[field.name, "value"]}
                          key={field.key}
                          rules={[
                            trimRequired("Необходимо ввести значение"),
                            uniqueValue(curValues, index),
                          ]}
                        >
                          <Input
                            addonAfter={
                              <Button
                                danger
                                size="small"
                                type="text"
                                onClick={() => {
                                  remove(index);
                                }}
                                icon={<DeleteOutlined />}
                              />
                            }
                            onChange={() => store.clearStatuses()}
                            addonBefore={curValues?.[index]?.id}
                            suffix={statusView(index)}
                          />
                        </Form.Item>
                        <MultiLangFields basePath={[field.name, "loc"]} />
                      </React.Fragment>
                    ))}
                    <Button
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        const pos = fields.length;
                        add({ id: newItemId, value: "", loc: {} });
                        // Поместить фокус на новое поле
                        delay(10).then(() => {
                          const elem = document.getElementById(
                            `values_${pos}_value`,
                          );
                          if (elem) elem.focus();
                        });
                      }}
                    >
                      Добавить значение
                    </Button>
                  </>
                )}
              </Form.List>
            </div>
            <div className={styles.vert}>
              <h3>
                <span>Добавление нескольких значений </span>
                <Tooltip
                  title="Вы можете скопировать столбец электронной таблицы и вставить сюда"
                  placement="bottomRight"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </h3>
              <Spin spinning={store.pasteCounter > 0}>
                <Input.TextArea
                  rows={5}
                  value={store.batchValues}
                  onChange={(e) => {
                    store.setBatchValues(e.currentTarget.value);
                  }}
                  onPaste={() => {
                    store.onPaste();
                  }}
                />
              </Spin>
              <div>
                <Button
                  disabled={store.batchDisabled}
                  onClick={() => {
                    const { added, ignored } = store.startBatch(form);
                    if (added)
                      notification.success({
                        message: `Добавлено элементов: ${added}`,
                      });
                    if (ignored)
                      notification.warning({
                        message: `Совпавших элементов: ${ignored}`,
                      });
                  }}
                >
                  {t("Add")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  },
);

const uniqueValue = (
  valuesList: ZAttributeValue[] | null,
  index: number,
): FormRule => ({
  validator: (rule, curValue) => {
    const double = valuesList?.find(
      ({ value }, i) => i !== index && value === curValue,
    );
    if (double) return Promise.reject(Error("Это значение уже использовано"));
    return Promise.resolve();
  },
});

export const AttrFields2Buttons: React.FC<PropsAttrFields2> = observer(
  ({ store }) => (
    <ExtraTools>
      <Button
        icon={<DeleteOutlined />}
        danger
        onClick={() => {
          store.startDeleteCurNode();
        }}
      >
        Удалить справочник
      </Button>
    </ExtraTools>
  ),
);
