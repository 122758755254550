import { ZBomPositionRow } from "../../BomTypes";
import {
  BomObjectsMap,
  getPosColorName,
  getPosMatType,
} from "../BomObjectsMap";
import {
  ColorRow,
  makeColorKey,
  makeMatKey,
  MaterialDict,
  MaterialRow,
} from "./BomPositionRowTypes";

export const buildMatDict = (
  rows: ZBomPositionRow[],
  objMap: BomObjectsMap,
): MaterialDict => {
  const matDict: MaterialDict = {};
  const getMatRow = (row: ZBomPositionRow): MaterialRow => {
    const matType = getPosMatType(row, objMap);
    const matKey = makeMatKey(matType.value);
    const item = matDict[matKey];
    if (item) return item;
    const newItem: MaterialRow = {
      type: "material",
      key: matKey,
      name: matType.label,
      colors: {},
    };
    matDict[matKey] = newItem;
    return newItem;
  };
  const getColorRow = (
    row: ZBomPositionRow,
    matItem: MaterialRow,
  ): ColorRow => {
    const colorKey = makeColorKey(matItem.key, row.colorEntity?.id ?? null);
    const colorItem = matItem.colors[colorKey];
    if (colorItem) return colorItem;
    const newItem: ColorRow = {
      type: "color",
      key: colorKey,
      name: `${matItem.name}: ${getPosColorName(row, objMap)}`,
      rows: [],
    };
    // eslint-disable-next-line no-param-reassign
    matItem.colors[colorKey] = newItem;
    return newItem;
  };
  rows.forEach((row) => {
    const matItem = getMatRow(row);
    const colorItem = getColorRow(row, matItem);
    colorItem.rows.push(row);
  });
  return matDict;
};
