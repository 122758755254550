import { z } from "zod";
import { zPermissionsList } from "./Permission";

export const zEntityValue = z.string().array().nullable();
export type ZEntityValue = z.infer<typeof zEntityValue>;

export const zEntityField = z.object({
  attributeId: z.number(),
  values: zEntityValue,
  permissions: zPermissionsList.nullable().optional(),
});
export type ZEntityField = z.infer<typeof zEntityField>;

export const zEntity = z.object({
  id: z.number(),
  objectId: z.number(),
  attributeValues: zEntityField.array(),
  permissions: zPermissionsList.nullable().optional(),
  stateId: z.number().nullable().optional(),
});

export type ZEntity = z.infer<typeof zEntity>;
