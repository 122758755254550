// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ScrollableTable-ScrollableTable-module__frame--kz9Ly {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.src-components-ScrollableTable-ScrollableTable-module__header--KqOsl {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 8px;
}
.src-components-ScrollableTable-ScrollableTable-module__header--KqOsl .src-components-ScrollableTable-ScrollableTable-module__title--P6RXo {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  border: none;
  padding: 0;
  margin: 0;
}
.src-components-ScrollableTable-ScrollableTable-module__header--KqOsl .src-components-ScrollableTable-ScrollableTable-module__tools--jZRN2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}
.src-components-ScrollableTable-ScrollableTable-module__table--gMZuq {
  flex: 1;
  overflow: hidden;
}
.src-components-ScrollableTable-ScrollableTable-module__paginator--UDVXR {
  text-align: center;
  height: auto;
  padding: 0 0 6px 0;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/components/ScrollableTable/ScrollableTable.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;AAAJ;AAJA;EAOQ,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,UAAA;EACA,SAAA;AAAR;AAdA;EAiBQ,OAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,QAAA;AAAR;AAGA;EACI,OAAA;EACA,gBAAA;AADJ;AAGA;EACI,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;AADJ","sourcesContent":[".frame {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.header {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 16px 8px;\n\n    .title { \n        color: rgba(0, 0, 0, 0.85);\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 150%;\n        border: none;\n        padding: 0;\n        margin: 0;\n    }\n    .tools {\n        flex: 1;\n        display: flex;\n        flex-direction: row;\n        justify-content: flex-end;\n        gap: 8px;\n    }\n}\n.table {\n    flex: 1;\n    overflow: hidden;\n}\n.paginator {\n    text-align: center;\n    height: auto;\n    padding: 0 0 6px 0; // Если здесь будет отсутствовать вертикальный паддинг, то появится ненужный автоскролл у вышележащего контейнера\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frame": `src-components-ScrollableTable-ScrollableTable-module__frame--kz9Ly`,
	"header": `src-components-ScrollableTable-ScrollableTable-module__header--KqOsl`,
	"title": `src-components-ScrollableTable-ScrollableTable-module__title--P6RXo`,
	"tools": `src-components-ScrollableTable-ScrollableTable-module__tools--jZRN2`,
	"table": `src-components-ScrollableTable-ScrollableTable-module__table--gMZuq`,
	"paginator": `src-components-ScrollableTable-ScrollableTable-module__paginator--UDVXR`
};
export default ___CSS_LOADER_EXPORT___;
