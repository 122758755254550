import { t } from "i18next";
import dayjs from "dayjs";

export const dateViewFormat = () => t("DateFormat", "DD.MM.YYYY");
export const dateTimeViewFormat = () => t("DateTimeFormat", "DD.MM.YYYY HH:mm");
export const dateValueFormat = "YYYY-MM-DD";
export const formatDate2ValueFormat = (date: string): string => {
  const md = dayjs(date);
  return md.isValid() ? md.format(dateValueFormat) : "";
};

export const viewDate = (rawDate?: string | null): string =>
  rawDate ? dayjs(rawDate).format(dateViewFormat()) : "";
