import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadFile, UploadProps } from "antd";
import { t } from "i18next";
import React from "react";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { onError as onCommonError } from "src/common/onError";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  makeFileItem,
  makeUploadMethods,
  onFileListChanged,
  TFilesDict,
  UploadInfo,
} from "src/components/PlmUploader";
import { AttrImageCarouselProps } from "src/common/attrEdit/components/ZAttrImageCarousel";
import { UploadChangeParam, RcFile } from "antd/es/upload";
import { loadFileInfo } from "src/common/apiFiles";
import styles from "./ImageCarousel.module.less";
import { ImageCarouselInner } from "./ImageCarouselInner";
import { ImageCarouselScroll } from "./ImageCarouselScroll";
import { ImageCarouselDragger } from "./ImageCarouselDragger";

type PropsImageCarousel = AttrImageCarouselProps &
  Omit<UploadProps, "fileList"> & {
    value?: string[];
    onChange?(v: string[] | undefined): void;
  };

export const ImageCarousel: React.FC<PropsImageCarousel> = (props) => {
  const {
    value,
    onChange,
    disabled,
    multiple,
    maxCount,
    maxSize,
    accept,
    imageHeight,
    ...uploadProps
  } = props;
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [dict, setDict] = React.useState<TFilesDict>({});
  const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
  const [isMultiMode, setIsMultiMode] = React.useState(false);
  const [activeImage, setActiveImage] = React.useState(0);
  const uploadMaxCount = (multiple ? maxCount : undefined) ?? 1;

  React.useEffect(() => {
    const guids: string[] = value ?? [];
    setFileList(
      guids.map((uid) => {
        const file = dict[uid];
        return file ?? makeFileItem(true, uid);
      }),
    );
    guids
      .filter((uid) => !dict[uid])
      .map((uid) =>
        loadFileInfo(uid)
          .then(({ fileName, size }) => {
            const file: UploadFile = makeFileItem(true, uid, fileName, size);
            setDict((prev) => ({ ...prev, [uid]: file }));
            setFileList((prev) =>
              prev.map((item) => (item.uid === uid ? file : item)),
            );
          })
          .catch(onCommonError),
      );

    if (guids.length > 0 && multiple) {
      setIsMultiMode(true);
    } else {
      setIsMultiMode(false);
      setActiveImage(0);
    }
  }, [value]);

  React.useEffect(() => {
    setIsSettingsOpen(false);
  }, [isMultiMode]);

  const { upload, beforeUpload } = makeUploadMethods(true, fileList, maxSize);

  const handleChange = async (info: UploadChangeParam) => {
    const { status, response } = info.file;
    setFileList(info.fileList);
    if (status === "done") {
      // eslint-disable-next-line no-param-reassign
      info.file.uid = response.filePath;
      await onFileListChanged(info.fileList, onChange);
      setDict((prev) => ({
        ...prev,
        [response.filePath]: info.file as RcFile,
      }));
      setFileList(info.fileList);
    }
  };

  const onRemove = (file: UploadFile) => {
    onFileListChanged(
      fileList.filter(({ uid }) => uid !== file.uid),
      onChange,
    );
  };

  const imageUploadProps: UploadProps = {
    ...uploadProps,
    fileList,
    customRequest: async (options) => upload(options),
    onChange: handleChange,
    onRemove,
    disabled,
    maxCount: uploadMaxCount,
    multiple,
    beforeUpload,
    accept,
  };

  const handleDelete = (index: number) => {
    const fileToDelete = fileList[index];
    if (!fileToDelete) return;
    onRemove(fileToDelete);
    const newActiveImage = index - 1;
    if (newActiveImage >= 0) {
      setActiveImage(newActiveImage);
    }
  };

  const handleChangeOrder = (list: UploadFile[]) => {
    setFileList(list);
    onFileListChanged(list, onChange);
  };

  return (
    <div className={styles.imageCarousel}>
      {fileList[activeImage] && (
        <ImageCarouselInner
          image={fileList[activeImage]}
          onDelete={() => handleDelete(activeImage)}
          disabled={disabled ?? false}
          height={imageHeight}
        />
      )}
      {isMultiMode ? (
        <>
          <ImageCarouselScroll
            fileList={fileList}
            onImageSelect={setActiveImage}
            currentImageIndex={activeImage}
          />
          <Button
            onClick={() => setIsSettingsOpen(true)}
            disabled={disabled}
            className={styles.imageUploadButton}
          >
            {t("Modify")}
          </Button>
          <ModalVertFixed
            height="80vh"
            open={isSettingsOpen}
            title={t("Image modification")}
            className={styles.imageUploadModal}
            closable={false}
            maskClosable={false}
            footer={
              <div className={styles.modalFooter}>
                <Button
                  className={styles.finishButton}
                  type="primary"
                  onClick={() => setIsSettingsOpen(false)}
                >
                  {t("Finish")}
                </Button>
              </div>
            }
          >
            {(fileList?.length ?? 0) >= uploadMaxCount && (
              <UploadInfo
                maxCount={uploadMaxCount}
                accept={accept}
                maxSize={maxSize}
              />
            )}
            <ImageCarouselDragger
              uploadProps={imageUploadProps}
              maxSize={maxSize}
              fileList={fileList}
              onOrderChange={handleChangeOrder}
              onDelete={handleDelete}
            />
          </ModalVertFixed>
        </>
      ) : (
        <Upload
          className={styles.uploadSingle}
          {...imageUploadProps}
          listType="picture"
        >
          <Button
            disabled={disabled}
            className={styles.imageUploadButton}
            icon={fileList.length > 0 ? undefined : <UploadOutlined />}
          >
            {fileList.length > 0 ? t("Modify") : t("Upload")}
          </Button>
        </Upload>
      )}
    </div>
  );
};

ImageCarousel.defaultProps = {
  value: undefined,
  onChange: undefined,
};
