import * as React from "react";
import { observer } from "mobx-react-lite";
import { onAntFormError } from "src/common/onAntFormError";
import { Modal, Button, Form, notification, Space } from "antd";
import { LoaderBox } from "src/components/LoaderBox";
import { t } from "i18next";
import { appStore } from "src/appStore";
import {
  CatNode,
  DeleteCatSubmitParams,
  InfoAboutDelete,
  TreeStore,
} from "../TreeStore";
import { CategoryTreeSelect } from "../CategoryTreeSelect";

const formName = "DeleteCategory";

interface PropsDeleteCategoryForm {
  current: CatNode;
  store: TreeStore;
  info: InfoAboutDelete;
  close(): void;
}

const DeleteCategoryForm: React.FC<PropsDeleteCategoryForm> = (
  props: PropsDeleteCategoryForm,
) => {
  const { current, store, info, close } = props;
  const [wait, setWait] = React.useState(false);
  const [form] = Form.useForm();

  const submit = (params: DeleteCatSubmitParams) => {
    setWait(true);
    store
      .deleteCategory({ ...params, node: current })
      .then(() => {
        close();
        notification.success({ message: t("Category deleted") });
      })
      .catch((e) => onAntFormError(e, formName))
      .finally(() => {
        setWait(false);
      });
  };

  const { hasChildren } = current.data;
  const { currentCompanyId } = appStore;

  return (
    <Form<DeleteCatSubmitParams>
      form={form}
      layout="vertical"
      onFinish={submit}
      name={formName}
    >
      {info.usedByCommodities && (
        <Form.Item
          label="В категории есть привязанные товары. Куда переместить товары?"
          name="newProdOwner"
          rules={[
            { required: true, message: "Категория не выбрана" },
            store.makeDeleteRule(current),
          ]}
        >
          <CategoryTreeSelect
            allowClear
            companyId={currentCompanyId}
            considerSelectable
          />
        </Form.Item>
      )}
      {hasChildren && (
        <Form.Item
          name="newParentId"
          label="В категории есть дочерние категории, укажите новую родительскую категорию"
          rules={[
            { required: true, message: "Категория не выбрана" },
            store.makeDeleteRule(current),
          ]}
        >
          <CategoryTreeSelect allowClear companyId={currentCompanyId} />
        </Form.Item>
      )}

      <Form.Item>
        <Space>
          <Button htmlType="submit" type="primary" danger loading={wait}>
            Подтвердить
          </Button>
          <Button htmlType="button" onClick={close}>
            Отмена
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

interface PropsDeleteCategoryModal {
  open: boolean;
  store: TreeStore;
  close(): void;
}

export const DeleteCategoryModal: React.FC<PropsDeleteCategoryModal> = observer(
  (props) => {
    const { open, close, store } = props;
    const { current } = store;
    React.useEffect(() => {
      if (open && current) {
        store.loadDeleteInfo(current);
      }
    }, [open]);

    if (!current) return null;

    return (
      <Modal
        open={open}
        onCancel={close}
        destroyOnClose
        title={<span>Категория &quot;{current.title}&quot; будет удалена</span>}
        centered
        footer={null}
      >
        <LoaderBox
          remoteData={store.infoAboutDelete}
          drawReady={(info) => (
            <DeleteCategoryForm
              current={current}
              store={store}
              info={info}
              close={close}
            />
          )}
        />
      </Modal>
    );
  },
);
