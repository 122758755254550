import * as React from "react";
import { observer } from "mobx-react-lite";
import { Alert, Button, Form, Input, Select, Spin } from "antd";
import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { ZObjectItem } from "src/types/ZObjectItem";
import {
  loadObjectAttrinbutesAll,
  loadObjects,
} from "src/pages/ManagementPage/objectsApi";
import { ZAttribute } from "src/types/ZAttribute";
import { ZObjectService } from "src/businessServices/businessServises.types";
import { AttrTypeName } from "src/types/AttrType";
import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { ZBomSettings } from "../BomTypes";
import styles from "./BomSettings.module.less";

const fk = (key: keyof ZBomSettings) => key;
function fkx(
  key1: "material",
  key2: keyof ZBomSettings["material"],
): [string, string];
function fkx(
  key1: "supplier",
  key2: keyof ZBomSettings["supplier"],
): [string, string];
function fkx(
  key1: "color",
  key2: keyof ZBomSettings["color"],
): [string, string];
function fkx(key1: keyof ZBomSettings, key2: string) {
  return [key1, key2];
}

interface PropsBomSettings {
  info: ZObjectService;
  typesMap: Record<number, string>;
}

const fieldNames = { value: "id", label: "name" };

export const BomSettings: React.FC<PropsBomSettings> = observer(
  ({ info, typesMap }) => {
    const form = Form.useFormInstance();
    const { loading, objects, attributes } = bomSettingsStore;
    React.useEffect(() => {
      bomSettingsStore.init(info, typesMap);
    }, [info]);
    const positionObjectId = Form.useWatch(fk("positionObjectId"));
    React.useEffect(() => {
      bomSettingsStore
        .loadAttrs(positionObjectId)
        .then(() => bomSettingsStore.checkPositions(bomObjectId));
    }, [positionObjectId]);
    const attributeLinkToMaterialId = Form.useWatch(
      fkx("material", "attributeLinkToMaterialId"),
    );
    React.useEffect(() => {
      if (objects.length > 0 && attributes.length > 0)
        bomSettingsStore.onMaterial(attributeLinkToMaterialId);
    }, [attributeLinkToMaterialId, attributes, objects]);
    const attributeLinkToSupplierId = Form.useWatch(
      fkx("supplier", "attributeLinkToSupplierId"),
    );
    React.useEffect(() => {
      if (objects.length > 0 && attributes.length > 0) {
        bomSettingsStore.onSupplier(attributeLinkToSupplierId);
      }
    }, [attributeLinkToSupplierId, attributes, objects]);
    const attributeLinkToColorId = Form.useWatch(
      fkx("color", "attributeLinkToColorId"),
    );
    React.useEffect(() => {
      if (objects.length > 0 && attributes.length > 0) {
        bomSettingsStore.onColor(attributeLinkToColorId);
      }
    }, [attributeLinkToColorId, attributes, objects]);
    const bomObjectId = Form.useWatch(fk("bomObjectId"));
    React.useEffect(() => {
      bomSettingsStore.checkBom(bomObjectId);
    }, [bomObjectId]);
    const { bomWarning, posWarning } = bomSettingsStore;

    return (
      <Spin spinning={loading}>
        <div className={styles.box}>
          <Form.Item
            name={fk("bomObjectId")}
            label="Объект для формирования BOM"
            rules={[{ required: true }]}
          >
            <Select
              options={objects}
              fieldNames={fieldNames}
              showSearch
              optionFilterProp="name"
            />
          </Form.Item>
          {bomWarning && <Alert type="warning" message={bomWarning} showIcon />}
          <Form.Item
            name={fk("positionObjectId")}
            label="Объект для формирования BOM-position"
            rules={[{ required: true }]}
          >
            <Select
              options={objects}
              fieldNames={fieldNames}
              showSearch
              optionFilterProp="name"
              onChange={() => {
                form.resetFields([
                  fkx("material", "attributeLinkToMaterialId"),
                  fkx("material", "attributeMaterialTypeId"),
                  fkx("material", "attributeImageId"),
                  fkx("material", "attributeNameId"),
                  fkx("supplier", "attributeLinkToSupplierId"),
                  fkx("supplier", "attributeNameId"),
                  fkx("supplier", "attributeAddressId"),
                  fkx("color", "attributeLinkToColorId"),
                  fkx("color", "attributeNameId"),
                  fkx("color", "attributeImageId"),
                  fk("optionalAttributes"),
                ]);
              }}
            />
          </Form.Item>
          {posWarning && <Alert type="warning" message={posWarning} showIcon />}
          <h3>Список обязательных позиций</h3>
          <Form.Item
            name={fkx("material", "attributeLinkToMaterialId")}
            label="Атрибут в BOM-position: материал "
            rules={[{ required: true }]}
          >
            <Select
              options={bomSettingsStore.materialAttrs}
              fieldNames={fieldNames}
              loading={bomSettingsStore.attrLoading}
              onChange={() => {
                form.resetFields([
                  fkx("material", "attributeImageId"),
                  fkx("material", "attributeMaterialTypeId"),
                  fkx("material", "attributeNameId"),
                ]);
              }}
            />
          </Form.Item>
          <div className={styles.threeCols}>
            <Form.Item label="Ссылка на связанный объект">
              <Input readOnly disabled value={bomSettingsStore.materialName} />
            </Form.Item>
            <Form.Item
              name={fkx("material", "attributeNameId")}
              label="Выбор атрибута названия материала"
              rules={[{ required: true }]}
            >
              <Select
                options={bomSettingsStore.matNameAttrs}
                fieldNames={fieldNames}
              />
            </Form.Item>

            <Form.Item
              name={fkx("material", "attributeMaterialTypeId")}
              label="Выбор атрибута типа материала"
              rules={[{ required: true }]}
            >
              <Select
                options={bomSettingsStore.matTypeAttrs}
                fieldNames={fieldNames}
              />
            </Form.Item>
            <Form.Item
              name={fkx("material", "attributeImageId")}
              label="Выбор атрибута изображения"
              rules={[{ required: true }]}
            >
              <Select
                options={bomSettingsStore.matImgAttrs}
                fieldNames={fieldNames}
                allowClear
              />
            </Form.Item>
          </div>
          <Form.Item
            name={fkx("supplier", "attributeLinkToSupplierId")}
            label="Атрибут в BOM-position: поставщик"
            rules={[{ required: true }]}
          >
            <Select
              options={bomSettingsStore.supplierAttrs}
              fieldNames={fieldNames}
              onChange={() => {
                form.resetFields([
                  fkx("supplier", "attributeNameId"),
                  fkx("supplier", "attributeAddressId"),
                ]);
              }}
            />
          </Form.Item>
          <div className={styles.threeCols}>
            <Form.Item label="Ссылка на связанный объект">
              <Input readOnly disabled value={bomSettingsStore.supplierName} />
            </Form.Item>
            <Form.Item
              name={fkx("supplier", "attributeNameId")}
              label="Выбор атрибута название"
              rules={[{ required: true }]}
            >
              <Select
                options={bomSettingsStore.suppTextAttrs}
                fieldNames={fieldNames}
              />
            </Form.Item>
            <Form.Item
              name={fkx("supplier", "attributeAddressId")}
              label="Выбор атрибута адрес"
            >
              <Select
                options={bomSettingsStore.suppTextAttrs}
                fieldNames={fieldNames}
                allowClear
              />
            </Form.Item>
          </div>
          <Form.Item
            name={fkx("color", "attributeLinkToColorId")}
            label="Атрибут в BOM-position: цвет"
            rules={[{ required: true }]}
          >
            <Select
              options={bomSettingsStore.colorAttrs}
              fieldNames={fieldNames}
              onChange={() => {
                form.resetFields([
                  fkx("color", "attributeNameId"),
                  fkx("color", "attributeImageId"),
                ]);
              }}
            />
          </Form.Item>
          <div className={styles.threeCols}>
            <Form.Item label="Ссылка на связанный объект">
              <Input readOnly disabled value={bomSettingsStore.colorName} />
            </Form.Item>
            <Form.Item
              label="Выбор атрибута название"
              name={fkx("color", "attributeNameId")}
              rules={[{ required: true }]}
            >
              <Select
                options={bomSettingsStore.colorNameAttrs}
                fieldNames={fieldNames}
              />
            </Form.Item>
            <Form.Item
              label="Выбор атрибута изображение"
              name={fkx("color", "attributeImageId")}
            >
              <Select
                options={bomSettingsStore.colorImgAttrs}
                fieldNames={fieldNames}
                allowClear
              />
            </Form.Item>
          </div>
          <h3>Необязательные позиции</h3>
          <Form.List name={fk("optionalAttributes")}>
            {(fields, { add, remove }) => (
              <div className={styles.formListBox}>
                {fields.map((field, i) => (
                  <div key={field.key} className={styles.listRow}>
                    <Form.Item
                      {...field}
                      name={[field.name, "attributeId"]}
                      rules={[{ required: true }]}
                    >
                      <Select
                        options={bomSettingsStore.attributes}
                        fieldNames={fieldNames}
                      />
                    </Form.Item>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => remove(i)}
                    />
                  </div>
                ))}
                <Button icon={<PlusCircleFilled />} onClick={() => add()}>
                  Добавить атрибут
                </Button>
              </div>
            )}
          </Form.List>
        </div>
      </Spin>
    );
  },
);

const bomSettingsStore = makeAutoObservable({
  loading: false,
  setLoading(flag: boolean) {
    this.loading = flag;
  },
  objects: [] as ZObjectItem[],
  setObjects(list: ZObjectItem[]) {
    this.objects = list;
  },
  info: null as ZObjectService | null,
  setInfo(newInfo: ZObjectService | null) {
    this.info = newInfo;
  },
  typesMap: {} as Record<number, string>,
  setTypesMap(m: Record<number, string>) {
    this.typesMap = m;
  },
  async init(info: ZObjectService, typesMap: Record<number, string>) {
    this.setTypesMap(typesMap);
    try {
      this.setBomWarning("");
      this.setPosWarning("");
      this.setLoading(true);
      this.setInfo(info);
      this.setObjects(await loadObjects());
    } catch (e) {
      onError(e);
    } finally {
      this.setLoading(false);
    }
  },
  bomWarning: "",
  setBomWarning(msg: string) {
    this.bomWarning = msg;
  },
  async checkBom(bomObjectId: number | null) {
    this.setBomWarning("");
    const { info } = this;
    if (bomObjectId && info) {
      const attrs = await loadObjectAttrinbutesAll(bomObjectId);
      const refAttr = attrs.find(
        ({ valueType, referenceId }) =>
          this.typesMap[valueType] === AttrTypeName.object &&
          referenceId === info.objectId,
      );
      if (!refAttr)
        this.setBomWarning("Нет связи между BOM-объектом текущим объектом");
    }
  },
  get materialAttrs(): ZAttribute[] {
    return this.attributes.filter(
      ({ valueType }) => this.typesMap[valueType] === AttrTypeName.object,
    );
  },
  get supplierAttrs(): ZAttribute[] {
    return this.materialAttrs;
  },
  get colorAttrs(): ZAttribute[] {
    return this.materialAttrs;
  },
  attributes: [] as ZAttribute[],
  setAttributes(list: ZAttribute[]) {
    this.attributes = list;
  },
  attrLoading: false,
  setAttrLoading(flag: boolean) {
    this.attrLoading = flag;
  },
  async loadAttrs(objId: number | null) {
    try {
      this.setPosWarning("");
      this.setAttributes([]);
      this.setAttrLoading(true);
      if (objId) {
        this.setAttributes(await loadObjectAttrinbutesAll(objId));
      }
    } catch (e) {
      onError(e);
    } finally {
      this.setAttrLoading(false);
    }
  },
  checkPositions(bomObjectId: number | null) {
    if (this.attributes.length > 0 && bomObjectId) {
      const attr = this.attributes.find(
        ({ referenceId }) => referenceId === bomObjectId,
      );
      if (!attr) {
        this.setPosWarning("Не найдено связи BOM и BOM-позиций");
      }
    }
  },
  matObjAttrs: [] as ZAttribute[],
  setMatObjAttrs(list: ZAttribute[]) {
    this.matObjAttrs = list;
  },
  materialName: "",
  setMaterialName(name: string) {
    this.materialName = name;
  },
  posWarning: "",
  setPosWarning(msg: string) {
    this.posWarning = msg;
  },
  async onMaterial(materialAttrId: number) {
    try {
      this.setMaterialName("");
      this.setMatObjAttrs([]);
      if (!materialAttrId) return;
      const matAttr = this.attributes.find(({ id }) => materialAttrId === id);
      if (!matAttr) throw Error("Атрибут не найден");
      const { referenceId } = matAttr;
      if (!referenceId) throw Error("Атрибут не содержит ссылки на объект");
      const matObj = this.objects.find(({ id }) => id === referenceId);
      if (!matObj) throw Error(`Не найден объект с id=${referenceId}`);
      this.setMatObjAttrs(await loadObjectAttrinbutesAll(matObj.id));
      this.setMaterialName(`${matObj.name} (${matObj.id})`);
    } catch (e) {
      onError(e);
    }
  },
  get matImgAttrs(): ZAttribute[] {
    return this.matObjAttrs.filter(
      ({ valueType }) => this.typesMap[valueType] === AttrTypeName.image,
    );
  },
  get matTypeAttrs(): ZAttribute[] {
    return this.matObjAttrs.filter(
      ({ valueType }) => this.typesMap[valueType] === AttrTypeName.dictSingle,
    );
  },
  get matNameAttrs(): ZAttribute[] {
    return this.matObjAttrs.filter(
      ({ valueType }) => this.typesMap[valueType] === AttrTypeName.string,
    );
  },
  supplierName: "",
  setSupplierName(name: string) {
    this.supplierName = name;
  },
  async onSupplier(supplierAttrId: number | null) {
    try {
      this.setSupplierName("");
      this.setSupplierObjAttrs([]);
      if (!supplierAttrId) return;
      const suppAttr = this.attributes.find(({ id }) => id === supplierAttrId);
      if (!suppAttr) throw Error("Атрибут не найден");
      const { referenceId } = suppAttr;
      if (!referenceId) throw Error("Атрибут не содержит ссылки на объект");
      const suppObj = this.objects.find(({ id }) => id === referenceId);
      if (!suppObj) throw Error(`Не найден объект с id=${referenceId}`);
      this.setSupplierObjAttrs(await loadObjectAttrinbutesAll(suppObj.id));
      this.setSupplierName(`${suppObj.name} (${suppObj.id})`);
    } catch (e) {
      onError(e);
    }
  },
  supplierObjAttrs: [] as ZAttribute[],
  setSupplierObjAttrs(list: ZAttribute[]) {
    this.supplierObjAttrs = list;
  },
  get suppTextAttrs(): ZAttribute[] {
    return this.supplierObjAttrs.filter(
      ({ valueType }) => this.typesMap[valueType] === AttrTypeName.string,
    );
  },

  colorName: "",
  setColorName(name: string) {
    this.colorName = name;
  },
  colorObjAttrs: [] as ZAttribute[],
  setColorObjAttrs(list: ZAttribute[]) {
    this.colorObjAttrs = list;
  },
  async onColor(colorAttrId: number | null) {
    try {
      this.setColorName("");
      this.setColorObjAttrs([]);
      if (!colorAttrId) return;
      const colorAttr = this.attributes.find(({ id }) => id === colorAttrId);
      if (!colorAttr) throw Error("Не найден атрибут");
      const { referenceId } = colorAttr;
      if (!referenceId) throw Error("Атрибут не содержит ссылки на объект");
      const colorObj = this.objects.find(({ id }) => id === referenceId);
      if (!colorObj) throw Error(`Не найден объект с id=${referenceId}`);
      this.setColorObjAttrs(await loadObjectAttrinbutesAll(colorObj.id));
      this.setColorName(`${colorObj.name} (${colorObj.id})`);
    } catch (e) {
      onError(e);
    }
  },
  get colorNameAttrs(): ZAttribute[] {
    return this.colorObjAttrs.filter(
      ({ valueType }) => this.typesMap[valueType] === AttrTypeName.string,
    );
  },
  get colorImgAttrs(): ZAttribute[] {
    return this.colorObjAttrs.filter(
      ({ valueType }) => this.typesMap[valueType] === AttrTypeName.image,
    );
  },
});
